.depthProfile {
    display: flex;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
}

.depthProfile .soilProfile {
    flex-grow: 1;
}
.soilProfile .soilBar--sand {
    background-color: #d4b178ff;
}
.soilProfile .soilBar--silt {
    background-color: #4b2802ff;
}

.soilProfile .soilBar--clay {
    background-color: #894e3dff;
}

/*Soil profile labels*/
.depthProfile .soilProfile .soilBar {
    text-align: -webkit-right;
}

.zoneOverviewBody .soilProfile .soilBar > p {
    text-align: left;
    width: 80px;
    margin-top: auto;
    margin-bottom: auto;
    top: 50%;
    transform: translate(0, -50%);
    position: relative;
}

.depthProfile .soilProfilePercentText {
    color: rgba(255, 255, 255, 0.95);
    transition: color 200ms;
}

/*Hiding text on text hidden*/
.depthProfile.depthProfile--textHidden .soilProfilePercentText {
    color: rgba(255, 255, 255, 0);
}
.depthProfile.depthProfile--textHidden p.soilProfileMoistureText {
    color: rgba(255, 255, 255, 0);
}

/*Moisture stuff*/
.moistureBar {
    min-width: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.11);
    line-height: 1.1;
    position: relative;
}
.moistureBar > .moistureLevel {
    bottom: 0;
    position: absolute;
    width: 100%;
    background: #3b9aff;
    color: white;
}
.moistureBar > .moistureLevel > .moistureLevelLabel {
    font-size: 12px;
    line-height: 1.05;
}
.moistureBar .moistureLevel p.soilProfileMoistureText {
    font-size: 14px;
}
.depthProfile.depthProfile--bumpSoilMoistureUp:not(.depthProfile--textHidden)
    .moistureBar
    .moistureLevel
    p.soilProfileMoistureText {
    transform: translateY(-100%) translateY(-10px);
    background-color: rgba(59, 192, 255, 0.33);
    border-radius: 5px;
    margin-left: 3px;
    margin-right: 3px;
    color: rgba(0, 0, 0, 0.93);
}
