.NDVIChipContainer {
    color: rgba(255, 255, 255, 0.9);
    /*width: 100%;*/
    flex-direction: row;
    position: relative;
    min-height: 45px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 12ch;
}

.NDVIChipContainer .NDVITitle {
    color: var(--palette-text);
    background: var(--palette-main);
    min-height: 15px;
    top: 0;
    border-radius: 8px 8px 0 0;
    display: flex;
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
}

.NDVIChipContainer div:not(.NDVITitle) {
    width: 50%;
    height: 100%;
    padding: 15px 7px 2px 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NDVIChipContainer div.NDVIZoneValue {
    border-radius: 8px 0 0 8px;
}

.NDVIChipContainer div.NDVIAreaValue {
    border-radius: 0 8px 8px 0;
}

.NDVIChipContainer .NDVIValueText {
    font-size: 0.95rem;
    line-height: 1;
}

.NDVIChipContainer .NDVILocationText {
    font-size: 0.7rem;
    line-height: 1;
}
