.SensoterraDeviceOverviewCardRoot {
    padding-top: 10px;
}
.SensoterraSMSAlertUpperLowerLimit {
    display: flex;
    flex-direction: row;
}
.SensoterraSMSAlertUpperLowerLimit > * {
    margin: 10px;
}
