.RankStepperRoot {
    display: flex;
    flex-direction: row;
    .RankStepperIcon {
        text-align: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: solid var(--palette-main) 2px;
        padding: 0.1rem 0;
        margin-top: auto;
        margin-bottom: auto;
        background: var(--palette-main);
        color: white;

        .RankStepperIconInput {
            color: white;
            font-size: 1.2rem;
            bottom: 0.35rem;

            .MuiInputBase-input {
                text-align: center;
            }
        }
    }
    .RankStepperControls {
        display: flex;
        flex-direction: column;
        button.MuiButtonBase-root.MuiIconButton-root {
            flex-shrink: 1;
            padding-top: 0;
            padding-bottom: 0;
            width: 2rem;
            height: 2rem;
        }
    }
}

.RankStepperRoot.RankStepperRoot--horizontalView {
    display: flex;
    flex-direction: row;
    .RankStepperIcon {
        order: 2;
    }
    .RankStepperControls {
        flex-direction: row;
        display: flex;
        justify-content: space-evenly;
        align-content: space-between;
        .UpButton {
            order: 3;
        }
        .DownButton {
            order: 1;
        }
    }
}
