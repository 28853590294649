.zoneOverviewStatusIconStyle {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
}

.zoneOverviewStatusChipStyle {
    color: var(--palette-text);
    /*background-color: rgba(0, 0, 0, 0.11);*/
    margin-right: 5px;
}
