.ManualModeToggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    //padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid 1px rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #f5f5f5;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.27);
    .SubcardTitle {
        text-align: center;
    }

    .ManualModeToggle__ModesContainer {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        &.ManualModeToggle__ModesContainer--sending .ManualModeToggle__IndividualMode {
            background-color: grey !important;
        }
        .ManualModeToggle__IndividualMode {
            transition: background-color ease-in-out 100ms;
            cursor: pointer;
            margin-top: 10px;
            margin-bottom: calc(10px + 2rem);
            padding-top: 10px;

            border-radius: 8px;
            border: solid 1px rgba(0, 0, 0, 0.19);
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.17);
            display: flex;
            flex-direction: column;
            min-width: 5rem;
            width: 5rem;
            max-width: 12ch;
            flex-grow: 2;
            align-items: center;
            position: relative;
            &.manualModeToggle__IndividualMode--currentMode {
                background-color: var(--palette-main);
                border: solid 1px var(--palette-dark);
                // color: rgba(255, 255, 255, 0.89);
            }
            &.manualModeToggle__IndividualMode--transitioningTo:not(.manualModeToggle__IndividualMode--currentMode) {
                animation: pulseManualToggleButton ease-in-out 1.5s infinite;
                // color: rgba(255, 255, 255, 0.89);
            }
            .ManualModeToggle__IndividualModeSubtext {
                pointer-events: none;
                position: absolute;
                top: calc(100% + 5px);
                left: 50%;
                transform: translateX(-50%);
                width: 25ch;
                text-align: center;
            }
        }
    }
    .ManualModeToggle__Explanation {
        text-align: center;
        border-radius: 10px;
        background-color: white;
        border: solid 1px rgba(0, 0, 0, 0.51);
        // border-bottom: none;
        padding: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
    }
    @keyframes pulseManualToggleButton {
        0% {
            background-color: #f6f6f6;
            border: solid 1px rgba(0, 0, 0, 0.19);
        }
        50% {
            background-color: var(--palette-main);
            border: solid 1px var(--palette-dark);
        }
        100% {
            background-color: #f6f6f6;
            border: solid 1px rgba(0, 0, 0, 0.19);
        }
    }
}
