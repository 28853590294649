.AllComponentDemos {
    margin: 0px;
    top: 0px;
    max-height: calc(100vh - var(--nav-bar-height));
    overflow: auto;

    .SingleCompDemo {
        margin: 2rem;
        border-bottom: 1px black solid;
        display: flex;
        flex-direction: row;
        align-items: center;
        .SingleCompDemo_Demo {
            width: 30%;
        }
        .SingleCompDemo_Description {
            width: 30%;
        }
        .SingleCompDemo_Source {
            width: 30%;

            position: relative;
            padding-bottom: 3rem;
            &.SingleCompDemo_Source--collapsed {
                max-height: 16rem;
                overflow: hidden;
            }
            .ExpandSourceButton {
                bottom: 0;
                position: absolute;
            }
        }
    }
}
