.ZoneProfileEditor .MuiPaper-root {
    max-width: 2000px;
    overflow-y: hidden;
}

.ZoneProfileEditor .ZoneProfileEditorContent {
    display: flex;
    overflow-x: hidden;
}

.ZoneProfileEditor .zoneProfileEditorFirstColumn {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    max-width: 100%;
}

.ZoneProfileEditor .zoneProfileEditorFirstColumn .zoneProfileTextFieldRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ZoneProfileEditor .zoneProfileFormFields {
    width: 100%;
}

.ZoneProfileEditor .titleWithIcons {
    display: flex;
    padding-left: 5px;
    margin-bottom: 3px;
}

.ZoneProfileEditor .iconStyle {
    color: rgba(0, 0, 0, 0.54);
    width: 20px;
}

.ZoneProfileEditor .helpIconStyle {
    color: rgba(0, 0, 0, 0.54);
    width: 24px;
}

.ZoneProfileEditor .varietalAvatar {
    font-size: 1.3rem;
    width: 48px;
    height: 48px;
}

.zoneDetailsSubtitles {
    font-size: 16px;
    padding-left: 5px;
    margin-bottom: 3px;
}

.ZoneProfileEditor .varietalTextFieldAutoComplete .MuiFormControl-root {
    margin-right: 0;
}

.ZoneProfileEditor .varietalTextField {
    width: 97%;
    min-width: 15rem;
}

.ZoneProfileEditor .ageTextField {
    max-width: 6rem;
}

.ZoneProfileEditor .ageTextField .MuiFormControl-root {
    margin-right: 0;
}

.ZoneProfileEditor .vineCloneTextField {
    width: 50%;
    margin-right: 0;
}

.ZoneProfileEditor .vineCloneTextField .MuiOutlinedInput-root {
    border-radius: 4px 0 0 4px;
}

.ZoneProfileEditor .vineCloneTextField .MuiOutlinedInput-notchedOutline {
    border-width: 1px 0 1px 1px;
}

.ZoneProfileEditor .rootCloneTextField {
    width: 50%;
    margin-left: 0;
}

.ZoneProfileEditor .rootCloneTextField .MuiOutlinedInput-root {
    border-radius: 0 4px 4px 0;
}

.totalZoneFlowRateTextField {
    width: 70%;
}

.totalZoneFlowRateCalculatorButton {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
}

.totalZoneFlowRateCalculatorPanel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
}

.pressureHeadTextField {
    width: 100%;
}

.pumpSelectorDropdown {
    width: 96%;
}

.ZoneProfileEditor .firstColumnDividerStyle {
    margin: 20px 0 12px 0;
}

.textFieldWithTooltipContainer {
    display: flex;
    flex-direction: column;
}

.zoneProfileEditorSecondColumn {
    max-width: 440px;
}

.zoneProfileEditorSecondColumn .soilLayerEditorTitle {
    font-size: 16px;
    padding-left: 5px;
}
.responsiveZoneEditorContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
}

.responsiveZoneEditorContainer > div {
    min-width: 12rem; /*change min width to whatever works*/
}

.responsiveZoneEditorBasicInfoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
