.MicrovalveStatusBody div.SensorSelectDropdownRoot {
    position: relative;
}

.ZoneSelectWrapper {
    display: flex;
    flex-shrink: 1;
    min-width: 3rem;
    width: 11rem;
    flex-grow: 3;
    > .ZoneSelect {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-shrink: 1;
        min-width: 2rem;
        width: 10rem;
        flex-grow: 3;
        padding: 10px 5px;

        &.ZoneSelectActiveValve--active {
            background-color: rgba(190, 246, 122, 0.21);
        }
        > .zoneSelectorTextField {
            .MuiInputBase-root {
                background-color: rgba(255, 255, 255, 0.36);
                backdrop-filter: blur(5px);
            }
        }
        div.SensorSelectDropdown {
            background-color: rgba(250, 250, 250, 0.44);
        }
        > .zoneSelectorTextField::after {
            content: var(--valveLabel);
            position: absolute;
            top: 50%;
            transform: translate(-100%, -50%) translateX(-2px);
        }
        > .SensorSelectDropdownRoot::after {
            content: var(--sensorLabel);
            position: absolute;
            top: 50%;
            transform: translate(-100%, -50%) translateX(-2px);
        }
        div.SensorSelectDropdownRoot {
            position: relative;
        }
        div.SensorSelectDropdown {
            padding-top: 0;
            margin-top: 7px;
            backdrop-filter: blur(5px);
        }
        &.ZoneSelectAlign--right {
            > .zoneSelectorTextField::after {
                right: 0;
                transform: translate(100%, -50%) translateX(2px);
            }
            > .SensorSelectDropdownRoot::after {
                right: 0;
                transform: translate(100%, -50%) translateX(2px);
            }
        }
    }
    .ZoneSelectEdge {
        min-width: 5px;
        max-width: 20px;
        flex-shrink: 4;
    }
    .ZoneSelectEdge__activity {
        min-width: 5px;
        max-width: 20px;
        flex-grow: 1;
        flex-shrink: 3;
    }
    .ZoneSelectEdge__Details {
        min-width: 1.5rem;
        border-radius: 10px;
    }
    &.ZoneSelectWrapperAlign--right {
        .ZoneSelectEdge__Details {
            border-radius: 0px 10px 10px 0px;
        }
    }
    &.ZoneSelectWrapperAlign--left {
        .ZoneSelectEdge__Details {
            border-radius: 10px 0px 0px 10px;
        }
    }
    &.ZoneSelectWrapperActiveValve--active {
        .ZoneSelectEdge__Details {
            background-color: rgba(190, 246, 122, 0.21);
        }
        .ZoneSelectEdge__activity {
            background-color: var(--palette-main);
        }
    }
}

.ZoneSelectWrapper.ZoneSelectWrapperBlueTint--enabled {
    > .ZoneSelect {
        &.ZoneSelectActiveValve--active {
            .SensorSelectDropdownRoot {
                .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-outlined {
                    background-color: rgb(241 253 227);
                }
            }
        }
        .zoneSelectorTextField {
            .MuiInputBase-root {
                background-color: #cbd9d945;
                backdrop-filter: blur(5px);
            }
            .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-outlined {
                background-color: white;
                padding-left: 5px;
                padding-right: 5px;
                border-radius: 6px;
            }
            .MuiFormLabel-root.MuiInputLabel-outlined {
                background-color: rgba(255, 255, 255, 0);
                transition:
                    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                    background-color 200ms ease-in-out;
            }
        }
        div.SensorSelectDropdown {
            background-color: #ccfff31a;
        }
    }
}
