.HTMLDynamicGraphCursor {
    background: rgba(255, 255, 255, 1);
    border: solid rgba(0, 0, 0, 0.9) 1px;
    position: absolute;
    border-radius: 0px 0px 5px 5px;
    border-top: none;
    top: -1px;
    height: calc(100% + 4px);
    z-index: 2;
    min-width: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%, 0);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.34);
    transition: left 10ms ease-in-out;
    justify-content: center;
    white-space: nowrap;

    &__text {
        font-size: 0.58rem;
        padding-left: 1.5px;
        padding-right: 1.5px;
        &--top {
            position: relative;
            top: 4px;
        }

        &--bottom {
            margin-bottom: 3px;
        }
    }
}
