.staticIrrigationGraphStrips {
    align-content: center;
    height: 100%;
    background-color: #4ec4db;
    position: absolute;
}

.staticIrrigationGraphStrips--actual {
    align-content: center;
    height: 50%;
    bottom: 0;
    background-color: #4e95db;
    position: absolute;
}

.staticIrrigationGraphBackground {
    height: 20px;
    background-color: #ebebeb;
    position: relative;
    overflow: hidden;
}

.staticIrrigationGraphInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex-grow: 1 !important;
        flex-basis: min-content;
        width: max-content;
    }

    &__text {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.9rem;
        white-space: nowrap;
        &.staticIrrigationGraphInfo__datetext {
            flex-grow: 0;
        }
        &--block {
            width: 9px;
            height: 9px;
            margin-right: 5px;
            margin-left: 15px;
        }

        &--intended {
            background-color: #4ec4db;
        }

        &--actual {
            background-color: #4e95db;
        }
    }
}
