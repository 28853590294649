.TotalZoneFlowRateCalculatorContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    min-height: 120px;
    justify-content: space-around;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);
}

.TotalZoneFlowRateCalculatorRow {
    display: flex;
    justify-content: space-around;
}

.emitterSpacingTextField {
    width: 100%;
}

.emitterFlowRateTextField {
    width: 100%;
}

.lengthOfTubingTextField {
    width: 100%;
}

.TotalZoneFlowRateCalculatorResult {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.TotalZoneFlowRateCalculatorResult .resultChip {
    font-size: 16px;
    box-shadow: 0 1px 0 1px rgb(0 0 0 / 15%);
}
