.NumericFieldWithUnit {
    display: flex;
    flex-direction: column;
    &.NumericFieldWithUnit--error {
        //.MuiTextField-root.NumericFieldWithUnit__TextInputActual .MuiOutlinedInput-notchedOutline{
        //    border-color: red;
        //}
        .MuiOutlinedInput-notchedOutline,
        .NumericFieldWithUnit__Identifier,
        .NumericFieldWithUnit__Dash {
            border-color: var(--errorNormal);
        }
    }
    .NumericFieldWithUnit__Body {
        display: flex;
        flex-direction: row;
        .MuiFormControl-root {
            height: 100%;
            .MuiOutlinedInput-root.MuiInputBase-root {
                height: 100%;
            }
        }
        .MuiTextField-root.NumericFieldWithUnit__TextInputActual {
            max-width: 12ch;
            flex-grow: 1;
            min-width: 6ch;
            .MuiFormHelperText-root.MuiFormHelperText-contained {
                position: absolute;
                top: 100%;
            }
            .MuiOutlinedInput-notchedOutline {
                height: 100%;
                top: 0;
                > legend {
                    height: 0px;
                    //background-color: transparent;
                    //width: 0;
                    //display: none;
                }
            }
        }
        .NumericFieldWithUnit__TextInput:not(:first-child) {
            .MuiOutlinedInput-notchedOutline {
                border-left-width: 0;
                border-top-left-radius: 1px;
                border-bottom-left-radius: 1px;
            }
        }
        .NumericFieldWithUnit__TextInput:not(:last-child) {
            .MuiOutlinedInput-notchedOutline {
                border-right-width: 0;
                border-top-right-radius: 1px;
                border-bottom-right-radius: 1px;
            }
        }
        > div {
            border-radius: 0px;
        }
        > div:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            .MuiOutlinedInput-notchedOutline {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
        > div:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            .MuiOutlinedInput-notchedOutline {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
    .NumericFieldWithUnit__Dash {
        min-width: 1rem;
    }

    .NumericFieldWithUnit__Identifier {
        white-space: nowrap;
        padding: 3px;
        //border-radius: 4px 0px 0px 4px;
        border: solid 1px rgba(0, 0, 0, 0.23);
    }
    .NumericFieldWithUnit__Identifier,
    .NumericFieldWithUnit__Dash {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.15);
        border-top: solid 1px rgba(0, 0, 0, 0.23);
        border-bottom: solid 1px rgba(0, 0, 0, 0.23);
    }
    .NumericFieldWithUnit__UnitDropdown {
        .MuiOutlinedInput-notchedOutline {
            height: 100%;
            width: 100%;
            top: 0;
            border-radius: 0px; // 0px 4px 4px 0px;
            > legend {
                background-color: transparent;
                width: 0;
                display: none;
            }
        }
    }
    .NumericFieldWithUnit__Footer {
        margin-bottom: 0.3rem;
        max-width: 100%;
        .helperText {
            padding-left: 1rem;

            color: rgba(0, 0, 0, 0.6);
        }
        .helperText.helperText--error {
            padding-left: 1rem;

            color: var(--errorNormal);
        }
    }
}
