.LengthUnitTextField > :first-child {
    padding-right: 0px;
    margin-right: -1px;
}
.LengthUnitTextField > :first-child > .MuiOutlinedInput-root > fieldset {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.LengthUnitTextField {
    display: flex;
    flex-direction: row;
}
.LengthUnitTextField > :nth-child(2) {
    flex-shrink: 0;
    margin-left: 0px;
    border-left: none;
}
.LengthUnitTextField > :nth-child(2) > .MuiOutlinedInput-root > fieldset {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.LengthUnitTextFieldTooltipFont {
    color: rgba(0, 0, 0, 0.6);
    /*font-family: "Roboto","Helvetica","Arial",sans-serif;*/
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: -1px 14px 0 14px;
}
