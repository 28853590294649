.VerificationTroubleshootAccordion {
    border-radius: 8px;
    box-shadow: none;
    padding: 0 1rem;
    margin-left: 56px;

    .VerificationTroubleshootAccordionTitle {
        min-height: 3rem;

        .MuiAccordionSummary-content {
            margin: 0;
            display: flex;
            align-items: center;
        }

        .TitleText {
            margin-left: 1rem;
        }
    }

    .MuiButtonBase-root.Mui-disabled {
        opacity: 1;
    }
}

.VerificationTroubleshootAccordion.Success {
    background-color: rgb(237, 247, 237);
    color: rgb(30, 70, 32);
}

.VerificationTroubleshootAccordion.Warning {
    background-color: rgb(229, 246, 253);
    color: rgb(1, 67, 97);
}

.VerificationTroubleshootAccordion::before {
    content: none;
}

.VerificationTroubleshootAccordion:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 0;
}

.VerificationTroubleshootAccordion.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded {
    // mui sucks mui sucks mui sucks
    margin-top: 0;
    margin-left: 56px;
}
