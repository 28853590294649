.ME.aoi {
    --status-color-inactive: rgba(151, 196, 222, 0.5);
    --status-color-selected: #73e8ff;
    --status-color-selected-dark: #01abb9;
    --status-color-normal: #29b6f6;
    --status-color-dimmed: #0771da;
    --status-color-dark: #004d96;
    --status-color-x-dark: #003c91;
}
.ME.aoi {
    stroke: var(--dark-shade, #82c926);
    stroke-width: 2pt;
    stroke-opacity: 0.02;
    fill-opacity: 0;
    fill: var(--main-color, #82c926);
    transition:
        stroke 200ms ease-in-out,
        stroke-width 150ms ease-in-out,
        fill 200ms ease-in-out,
        fill-opacity 300ms ease-in-out,
        stroke-opacity 200ms ease-in-out;
}
.ME.aoi:hover {
    stroke-opacity: 0.2;
    fill-opacity: 0;
    stroke-width: 3pt;
    fill: var(--dark-shade, #82c926);
}
.ME.aoi.ME--focused:hover {
    stroke-width: 3.5pt;
    fill-opacity: 0;
}
.ME.aoi.ME--focused {
    stroke-width: 3pt;
    fill-opacity: 0;
}
.ME.aoi.ME--inactive:hover {
    stroke-width: 2pt;
    stroke-opacity: 0.8;
    fill-opacity: 0;
}
.ME.aoi.ME--inactive {
    stroke-width: 1.5pt;
    stroke-opacity: 0.8;
    fill-opacity: 0;
}
