.CurrentIrrigationStatusBar {
    padding-bottom: 10px;
    //max-width: 12rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    &.CurrentIrrigationStatusBar--warning {
        .IrrigationStatusText {
            background-color: #ab000d;
            color: white;
            border-radius: 3px;
            padding: 3px;
            margin-bottom: 2px;
        }
        .irrigationBar {
            margin-top: 4px;
        }
    }
    .irrigationBar {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.09);
        border-radius: 0.35rem;
        .irrigationBarFill {
            height: 0.7rem;
            font-size: 0.49rem;
            background-color: var(--irrigation-main);
            color: white;
            text-align: right;
            min-width: 1rem;
            padding-right: 4px;
            border-radius: 0.35rem;
        }
    }
}
