.SoilEditorBase {
    .SoilLayerEditorTriangleHolder {
        max-width: 300px;
        max-height: 300px;
        position: relative;
        .SoilLayerDeleteButton {
            position: absolute;
            right: 0;
        }
    }
    .SoilLayerEditorTriangleHolder > .soilTriangleSVG {
        max-width: 100%;
    }
    .SoilLayerEditorTriangleHolder > div.soilDot {
        width: 8px;
        height: 8px;
        background: white;
        position: absolute;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        pointer-events: none;
        border: 2px solid var(--palette-dark);
    }
    .SoilLayerEditorTriangleHolder > div.indexLine {
        width: 6px;
        height: 2px;
        background: var(--palette-dark);
        position: absolute;
        border-radius: 2px 0px 0px 0px;
        transform: translate(0%, -50%);
        pointer-events: none;
        /*!*border-radius: 50%;*!*/
    }
    .SoilLayerEditorTriangleHolder > div.clayLine {
        transform: translate(0%, -50%);
    }
    .SoilLayerEditorTriangleHolder > div.sandLine {
        transform: translate(0%, -50%) rotate(-120deg);
        transform-origin: left;
    }
    .SoilLayerEditorTriangleHolder > div.siltLine {
        transform: translate(0%, -50%) rotate(120deg);
        transform-origin: left;
    }
    .SoilLayerEditorTriangleHolder > div.TextureLabel {
        position: absolute;
        transform: translate(-100%, -100%);
        font-size: 12px;
        background: var(--palette-main);
        border-radius: 3px 3px 0px 0px;
        padding-left: 3px;
        padding-right: 3px;
        pointer-events: none;
    }
    .soilTriangleSVG {
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
    .SoilTriangleRegion {
        fill-opacity: 0;
        fill: var(--palette-main);
        transition: fill-opacity 200ms;
    }
    .SoilTriangleRegion:hover {
        fill-opacity: 0.2;
    }
    .SoilTriangleRegion.SoilTriangleRegion--selected {
        fill-opacity: 0.4;
    }
    .SoilLayerDeleteButton {
        height: fit-content;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 30px;
        flex-grow: 2;
    }

    .DepthAndPresetRow {
        > .SoilPresetSelector {
            min-width: 10rem;
            flex-grow: 1;
            width: 50%;
        }
        > .SoilEditorDepthField {
            flex-shrink: 1;
            flex-grow: 1;
            width: 50%;
            min-width: 8rem;
            max-width: 10rem;
        }
    }
    .ClayAndSandRow {
        > div {
            flex-shrink: 1;
            flex-grow: 1;
            width: 50%;
        }
    }
}

.soilTriangleSVG {
    .legendBg {
        fill: #fff;
    }
    text {
        font-family: "Liberation Sans", Arial, Helvetica, sans-serif;
        fill: #000;
    }
    .legend {
        font-size: 23px;
        font-weight: 700;
    }
    .proportionLegend {
        font-size: 19px;
    }
    .legendArrow {
        fill: #000;
        stroke: #000;
        stroke-width: 1;
    }
    .soilTypeLimit {
        fill: none;
        stroke: #000;
        stroke-width: 3;
    }
}
