.staticGraphBackgroundContainer {
    display: flex;
    height: 120px;
    pointer-events: none;
    position: relative;

    .yAxisLabelColumn {
        font-size: 0.8rem;
        color: rgba(92, 92, 92, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        position: absolute;
        transform: translate(calc(-1 * var(--yAxisWidth, 32px)));
        width: var(--yAxisWidth, 32px);
        align-items: flex-end;
        .yAxisLabel {
            max-width: var(--yAxisWidth, 2rem);
            justify-content: flex-end;
            text-align: -webkit-right;
            right: 3px;
            transform: translate(0, 50%);
            .yAxisUnits {
                font-size: 0.5rem;
            }
        }
        .yAxisLabel.yAxisLabel--unit {
            transform: translate(0, 50%) rotate(-90deg);
            bottom: 50% !important;
            left: -6px;
            height: fit-content;
            width: fit-content;
        }
        .yAxisLabel:not(:last-child)::after {
            content: " ";
            background-color: rgba(0, 0, 0, 0.42);
            width: 5px;
            height: 2px;
            border-radius: 1px;
            left: calc(100% + 5px);
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }
        .yAxisLabel.yAxisLabel--drawLine:not(:last-child)::after {
            content: " ";
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.68) 33%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 10px 1px;
            background-repeat: repeat-x;
            background-color: rgba(0, 0, 0, 00);
            width: var(--graphWidth, 20px);
            border-radius: 1px;
            left: calc(100% + 3px);
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .yAxisGrid {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        position: absolute;

        .gridHorizontalLine {
            border-top: 1px dashed #ccc;
        }
    }

    .gridVerticalLine {
        position: absolute;
        border-right: 1px dashed darkgrey;
        z-index: 1;
    }

    .beginningText {
        font-size: 0.7rem;
        color: rgba(92, 92, 92, 0.8);
        transform: rotate(270deg);
        transform-origin: top left;
        top: 60%;
        position: absolute;
        white-space: nowrap;
        z-index: 1;
    }

    .dates {
        position: absolute;
        transform: translate(-50%, 0);
        top: calc(100% + 4px);
        z-index: 1;
    }

    // .dateDivider {
    //     top: calc(100% + 1px);
    //     z-index: 1;
    //     position: absolute;
    //     transform: translate(-50%, 0);
    //     border-right: 1px dotted darkgrey;
    //     height: 12.5%;
    // }
}
