.ProvideFeedbackModal {
    .SuccessOverlay {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
        backdrop-filter: blur(0px);
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        left: 0;
        top: 0;
        justify-content: center;
        .ThanksText {
            font-size: 4rem;
        }
        .FinishedFeedbackButton {
            margin-top: 10px;
        }
    }
    .FeedbackTextInput {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
    .SubmitButton {
        margin-top: 10px;
        margin-bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.ProvideFeedbackModal.ProvideFeedbackModal--success {
    .SuccessOverlay {
        opacity: 1;
        z-index: 100;
        pointer-events: all;
        background-color: rgba(255, 255, 255, 0.47);
        backdrop-filter: blur(10px);
    }
}
