.VerificationPerformanceOverview {
    min-height: 3rem;
    padding-top: 1rem;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2rem;
    position: relative;
    .FiniteStateGraphRoot {
        width: calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        min-width: unset;
        .FSGChunkWrapper {
            border-radius: 20px;
        }
    }
}

//this code is also used by the irrigation report valve open graph.
.ValveStateLegend {
    display: flex;
    margin-bottom: 10px;

    //.VerdiStateLegendTitle{
    //    color: rgba(0, 0, 0, 0.91);
    //    flex-grow: 10;
    //}
    > *:nth-last-child(3) {
        color: rgba(0, 0, 0, 0.91);
        flex-grow: 10;
    }
    > span:nth-last-child(1),
    > span:nth-last-child(2) {
        border-radius: 4px;
        color: white;
        padding: 3px;
    }
    > span:last-child {
        margin-left: 5px;
    }
}
.VerdiStateLegendEntry {
    background-color: var(--irrigation-dark);
}
.ScheduleLegendEntry {
    background-color: var(--irrigation-main);
}
