.DynamicGraphDataChip {
    border-radius: 6px;
    max-width: 61px;
    cursor: pointer;
    max-width: 61px;
    min-width: 61px;
    min-height: 4.5rem;
    padding: 0px 0px 0px 0px;
    margin: 3px;
    display: inline-block;
    flex-direction: column;
    background-color: rgba(var(--dataColor), 0.25);
    align-items: center;
    position: relative;
    justify-content: space-between;
    color: rgba(255, 255, 255, 1);
    box-shadow: #00000047 0px 0px 6px;
    transition: transform 100ms;
    border: solid rgba(var(--dataColor), 100) 2px;
    user-select: none;
    &.DynamicGraphDataChip--unfocused {
        --dataColor: 180, 180, 180 !important;
        background-color: rgba(0, 0, 0, 0.22);
    }
    &:hover {
        box-shadow: #00000047 0px 0px 8px;
        transform: scale(1.05);
    }
    .DynamicGraphDataChip--Body {
        border-top: solid rgba(var(--dataColor), 100) 2px;
        color: rgba(0, 0, 0, 0.83);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        justify-content: space-between;
        background-color: white;
        //margin: 3px;
        width: 100%;
        border-radius: 3px;
        flex-grow: 1;
        .DynamicGraphDataChipIconAndCurValue {
            display: flex;
            flex-direction: row;
            width: 50px;
            height: 30px;
            flex-grow: 1;
            .ChipIconWrapper {
                flex-grow: 1;
                min-width: 1rem;
                max-width: 50%;
                position: relative;
                text-align: center;
                // display: flex;
                // justify-content: center;
                .DynamicGraphDataChipIcon {
                    //position:absolute;
                    ////-webkit-filter: blur(5px);
                    ////-moz-filter: blur(5px);
                    ////-o-filter: blur(5px);
                    ////-ms-filter: blur(5px);
                    ////filter: blur(1px);
                    // display: flex;
                    color: rgba(var(--dataColor), 0.4);
                    max-width: 100%;
                    min-height: unset;
                    height: 100%;
                    max-height: 100%;
                    left: 0;
                    width: unset;
                    aspect-ratio: 1 / 2;
                    margin-left: 2px;
                }
            }

            .DynamicGraphDataChipCurValue {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding-left: 2px;
                padding-right: 2px;
                align-items: center;
                .ChipUnit {
                    line-height: 1;
                    z-index: 1;
                    //margin-left:50%;
                    font-size: 10px;
                }
                .ChipValue {
                    //margin-left:50%;
                    line-height: 1;
                    margin-top: 3px;
                }
            }
        }
    }
    > span {
        flex-grow: 1;
    }
    .TypographyWithMaxWidthSuper.TypographyWithMaxWidth {
        transform-origin: top center;
    }
    .ChipSensor {
        bottom: -5px;
        max-height: 1rem;
        font-size: 8px;
        min-height: 0;
        margin: 0;
        padding: 0;
        flex-grow: 0 !important;
    }

    .ChipTitleWrapper {
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ChipTitle {
        color: rgba(0, 0, 0, 0.88);
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
