.relativeIrrigationDurationContainer {
    max-width: 440px;
    position: relative;
}

.relativeIrrigationDurationContainer .titleContainer {
    display: flex;
    justify-content: space-between;
}

.relativeIrrigationDurationContainer .recommendedValueChip {
    background-color: #26c6da;
    color: white;
    height: 26px;
    position: absolute;
    margin-top: -2px;
    left: 14.5%; /* chip will be centered at 0% */
    /*box-shadow: 0 1px 0 1px rgb(0 0 0 / 15%);*/
}

.relativeIrrigationDurationContainer .recommendedValueMarker {
    width: 0;
    height: 0;
    border-left: 8px solid #be101000;
    border-right: 8px solid #10ff0000;
    border-bottom: 16px solid #26c6da;
    position: absolute;
    margin-top: -16px;
    transform: translateX(-50%);
    /*border-left: 8px solid transparent;*/
    /*border-right: 8px solid transparent;*/
    /*border-bottom: 24px solid #26c6da;*/
    /*position: absolute;*/
    /*margin-top: -23px;*/
    left: 20.8%; /* marker will be at 0% */
}

.relativeIrrigationDurationSlider {
    display: flex;
    margin-top: 6px;
}
.relativeIrrigationDurationSlider .MuiSlider-root {
    margin-top: 5px;
}

.exampleSchedulerContainer {
    padding: 6px 10px 6px 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-top: 34px;
}

.exampleSchedulerContainer .exampleSchedulerTitle {
    font-size: 15px;
    margin-bottom: 8px;
}

.irrDurDemoCalendarIcon {
    width: 19px;
    height: 19px;
    color: rgba(0, 0, 0, 0.54);
    margin-right: 5px;
}

.irrigationDurationDemoContainer {
    display: grid;
    grid-template-columns: 1.7fr 3fr;
}

.irrigationDurationDemoContainer .thisZoneDemoTitle {
    font-size: 15px;
    height: 45px;
    border-bottom: solid #00000033 1px;
    border-right: solid #00000033 1px;
}
.irrigationDurationDemoContainer .thisZoneDemoBarGrid {
    height: 45px;
    border-bottom: solid #00000033 1px;
    display: flex;
    align-items: center;
}
.irrigationDurationDemoContainer .defaultZoneDemoTitle {
    font-size: 15px;
    height: 45px;
    border-right: solid #00000033 1px;
}
.irrigationDurationDemoContainer .defaultZoneDemoBarGrid {
    height: 45px;
    display: flex;
    align-items: center;
}

.irrigationDurationDemoContainer .thisZoneDemoBar {
    background: #26c6da;
    height: 30px;
    border-radius: 4px;
    width: 50%;
}
.irrigationDurationDemoContainer .defaultZoneDemoBar {
    background: #26c6da;
    height: 30px;
    border-radius: 4px;
    width: 50%;
}
