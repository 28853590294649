.SensorRecentValuesPreview {
    position: absolute;
    top: 100%;
    background-color: rgba(0, 0, 0, 0.07);
    width: 100%;
    border-radius: 0 0 8px 8px;
    min-height: 2rem;
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    > div {
        margin-top: auto;
        margin-bottom: auto;
    }
}
.ZoneSelectWrapperAlign--right {
    .SensorRecentValuesPreview {
        flex-direction: row-reverse;
    }
}
.SensorRecentValuesPreview.SensorRecentValuesPreview--empty {
    max-height: 0;
    min-height: 0;
}
