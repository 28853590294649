@keyframes bgColorPulseAnimation {
    0% {
        background-color: #f6f6f6;
        border: solid 1px rgba(0, 0, 0, 0.1);
    }
    40%,
    60% {
        background-color: var(--palette-main);
    }
    100% {
        background-color: #f6f6f6;
        border: solid 1px rgba(0, 0, 0, 0.1);
    }
}

@keyframes textFadePulseAnimation {
    0% {
        opacity: 0.35;
    }
    40%,
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0.35;
    }
}

.ActiveStepIconPulseAnimation {
    animation: bgColorPulseAnimation 2s infinite;
}

.ActiveStepLabelPulseAnimation {
    animation: textFadePulseAnimation 2s infinite;
}
