.SensorSettings .SettingsItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 7px;
}
.SensorSettings .SettingsItem > p {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
}
