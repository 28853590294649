.staticGraphHeaderContainer {
    margin-bottom: 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;

    &__chooseDate {
        display: flex;
        align-items: center;

        &--picker {
            margin-top: 8px;
            margin-left: 5px;
        }
    }
}

.staticGraphsContainer {
    --yAxisWidth: 42px;
    position: relative;
    padding-left: var(--yAxisWidth, 32px);
    user-select: none;
}

.StaticZoneGraphComponent {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    min-width: 100%;
    .SeriesGraphWrapper {
        flex-grow: 1;
        border: 1px solid darkgrey;
    }
}

.graphBottomBtns {
    &__wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__btn {
        padding-left: 0;
        padding-right: 0;
    }
}
