.overlayEntity {
    opacity: 0;
    transition: opacity 200ms;
}
.ME.overlayEntity {
    opacity: 0;
    display: none;
    background-color: transparent;
}
.ME.ME--focused.overlayEntity {
    opacity: 0.7;

    display: unset;
}
.ME.ME--selected.overlayEntity {
    opacity: 0.7;
    display: unset;
}
