.UserManagerField--APIKey {
    width: 60vw;
}
.AccountManagerDrawer {
    flex-shrink: 0;
    width: 300px;
    margin-right: 20px;
}
.AccountManagerDrawer > div {
    width: 300px;
}
