.SensorSelectDropdownRoot {
    display: flex;
    flex-direction: column;
    position: relative;
}

.SensorSelectDropdownRoot .SensorSelectDropdown {
    width: 100%;
    display: flex;
    flex-direction: row;
    .MuiFilledInput-root {
        flex-grow: 2;
        text-align: left;
        // subtract 15 px to accommodate the width of the settings cog
        max-width: calc(100% - 15px);
        background-color: rgba(0, 0, 0, 0.07);
        &:hover {
            background-color: rgba(0, 0, 0, 0.17);
        }
        border-radius: 4px 0px 0px 0px;
        &.TextFieldAlignmentOverride--right {
            text-align: right;
            border-radius: 0px 4px 0px 0px;
        }
    }
    button.SensorDropdownSettingsButton {
        border-radius: 0px 4px 0px 0px;
        width: 24px;
        background-color: rgba(0, 0, 0, 0.07);
        border-bottom: solid 1px rgba(0, 0, 0, 0.47);
        border-left: solid 1px rgba(0, 0, 0, 0.47);
        transition: background-color 100ms;
        &:hover {
            background-color: rgba(0, 0, 0, 0.17);
        }
    }
}
.ZoneSelectAlign--right {
    .SensorSelectDropdownRoot .SensorSelectDropdown {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        > label.MuiInputLabel-root {
            //left: 24px;
        }
        button.SensorDropdownSettingsButton {
            border-radius: 4px 0px 0px 0px;

            border-left: none;
            border-right: solid 1px rgba(0, 0, 0, 0.47);
        }
        .MuiSelect-iconFilled {
            left: 7px;
            right: unset;
        }
        .MuiSelect-select.MuiSelect-filled.MuiInputBase-input {
            padding-right: 10px;
            padding-left: 32px;
        }
    }
}
