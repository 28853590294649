.m .SproutVerificationRoot {
    max-width: 100%;
    overflow: hidden;
}
.SproutVerificationRoot {
    display: flex;
    flex-direction: column;
    --curStateFlagDark: #202c7a;
    --curStateFlagNormal: #303f9f;
    --curStateFlagLight: #3f4db6;
    background-color: #f1f1f1;
    border-top: solid rgba(0, 0, 0, 0.41) 1px;
    position: relative;
    .SproutVerificationRoot__Title {
        text-align: center;
        padding-top: 9px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .SproutVerificationRoot__HelpIcon {
        top: 4px;
        right: 4px;
        position: absolute;
    }
    .SproutVerificationTabs {
        padding-top: 2rem;
        display: flex;
        flex-direction: row;

        overflow: clip;
        .SproutVerificationOverallStatus {
            max-width: 3.5rem;
            padding-right: 6px;
            flex-grow: 0;
        }
        .IndividualVerificationTab {
            cursor: pointer;
            border-bottom: none;
            flex-grow: 1;
            min-width: 5rem;
            //padding: 5px;
            --tabBackgroundColor: #c9c9c9;
            position: relative;
            //border: solid 2px rgba(0, 0, 0, 0.09);
            //border-bottom: none;
            background: var(--tabBackgroundColor);
            display: flex;
            flex-direction: row-reverse;
            border-radius: 5px 5px 0px 0px;
            margin-right: 3px;
            margin-left: 3px;
            box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.36);
            transition:
                box-shadow 200ms ease-in-out,
                background-color 100ms ease-in-out;
            z-index: 2;
            border-bottom: solid 10px var(--tabBackgroundColor);
            //box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.46);
            .CurrentlyActiveBanner {
                position: absolute;
                width: 8ch;
                border-radius: 4px 4px 0px 0px;
                background: linear-gradient(
                    0deg,
                    var(--curStateFlagDark),
                    var(--curStateFlagNormal),
                    var(--curStateFlagLight)
                );
                background-color: #303f9f;
                border: solid 2px var(--curStateFlagDark); //#303f9f;// #a22e0a;
                //background: linear-gradient(0deg, #232f75, #303f9f, #303f9f );
                //background-color: #303f9f;
                //border: solid 2px #283593;
                bottom: 100%;
                left: 50%;
                transform: translate(-50%);
                text-align: center;
                color: white;
                z-index: -1;
                box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.46);
            }
            .CurrentlyActiveBanner::after {
                content: " ";
                height: 0;
                width: 100%;
                bottom: 0;
                box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
                position: absolute;
                left: 0;
            }
            &.IndividualVerificationTab--wide {
                flex-direction: row-reverse;
                width: 50%;
                .IdentifierHeader {
                    display: none;
                }
                > div.tabElement:first-of-type {
                    border-top-right-radius: 5px;
                }
                > div.tabElement:last-of-type {
                    border-top-left-radius: 5px;
                }
                .PassFailContainer.VerificationStatusIcon {
                    height: unset;
                    border-bottom-left-radius: 5px;
                }
            }
            &.IndividualVerificationTab--active {
                border-top: solid 2px var(--curStateFlagDark); // #d84315;
                border-left: solid 2px var(--curStateFlagDark); //#d84315;
                border-right: solid 2px var(--curStateFlagDark); //#d84315;
                //border-bottom: none;
                margin-top: -2px;
            }
            &.IndividualVerificationTab--narrow {
                flex-direction: column-reverse;
                .IdentifierLongName {
                    display: none;
                }
                > div:last-child {
                    border-radius: 5px 5px 0px 0px;
                }
            }

            &.IndividualVerificationTab--selected {
                --tabBackgroundColor: #f1f1f1;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.56);
                //border: solid 2px rgba(0, 0, 0, 0.19);
                //box-shadow: 0px -3px 6px -3px rgba(0, 0, 0, 0.06),
                //            3px -3px 6px -3px rgba(0, 0, 0, 0.36),
                //            -3px -3px 6px -3px rgba(0, 0, 0, 0.36),
                //            0px -6px 12px -6px rgba(0, 0, 0, 0.26),
                //box-shadow:
                //    6px 0px   3px -3px rgba(0, 0, 0, 0.26),
                //    -6px -3px 3px -3px rgba(0, 0, 0, 0.26),
                //    0px -6px  3px -3px rgba(0, 0, 0, 0.26),
                //    -32px -32px  15px -30px rgba(0, 0, 0, 0.16),
                //    32px -32px   15px -30px rgba(0, 0, 0, 0.16),
                //0px -6px 12px -6px rgba(0, 0, 0, 0.26),
                z-index: 12;
            }
            &.IndividualVerificationTab--selected::before {
                content: " ";
                position: absolute;
                width: 100%;
                height: calc(100% + 20px);
                top: 0;
                z-index: -100;
                border-radius: 5px;
                //background: #efefef;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.56);
                //border: solid 2px rgba(0, 0, 0, 0.19);
                //box-shadow: 0px -3px 6px -3px rgba(0, 0, 0, 0.06),
                //            3px -3px 6px -3px rgba(0, 0, 0, 0.36),
                //            -3px -3px 6px -3px rgba(0, 0, 0, 0.36),
                //            0px -6px 12px -6px rgba(0, 0, 0, 0.26),
                //box-shadow:
                //        6px 0px   3px -3px rgba(0, 0, 0, 0.26),
                //        -6px -3px 3px -3px rgba(0, 0, 0, 0.26),
                //        0px -6px  3px -3px rgba(0, 0, 0, 0.26),
                //        -32px -32px  15px -30px rgba(0, 0, 0, 0.16),
                //        32px -32px   15px -30px rgba(0, 0, 0, 0.16),
                //0px -6px 12px -6px rgba(0, 0, 0, 0.26),
            }
            .PassFailContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 1.5rem;
                .TabVerificationIcon.VerificationStatusIcon {
                    box-shadow: unset;
                }
            }
            &.IndividualVerificationTab--unselected .PassFailContainer {
                filter: brightness(85%);
            }

            .IdentifierContainer {
                //border-radius: 8px;
                // background-color: rgba(255, 255, 255, 0.32);
                padding: 2px;
                //margin-left: 4px;
                //margin-right: 4px;
                //background: var(--tabBackgroundColor);
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                .IdentifierBody {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    width: 100%;
                }
                > div {
                    text-align: center;
                }
                .IdentifierHeader {
                    line-height: 1;
                }
                .IdentifierFooter {
                    display: none;
                }
            }
            .IdentifierLongName {
                // background-color: rgba(255, 255, 255, 0.32);
                background: var(--tabBackgroundColor);
                padding: 2px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 5px;
                justify-content: center;
                .UpdatedAt {
                    color: rgba(0, 0, 0, 0.37);
                }
            }
            .Spacer {
                background: var(--tabBackgroundColor);
                flex-grow: 40;
                width: 0;
                transition:
                    box-shadow 200ms ease-in-out,
                    background-color 100ms ease-in-out;
            }
            .IndividualValveState {
                background: var(--tabBackgroundColor);
                display: flex;
                flex-direction: column;
                align-items: center;
                transition:
                    box-shadow 200ms ease-in-out,
                    background-color 100ms ease-in-out;
            }
        }
    }
    .SproutVerificationTabPanel {
        width: 100%;
        //border: solid 2px rgba(0, 0, 0, 0.09);
        //border-top: solid 2px green;
        //border-top: none;
        //border-radius: 4px;
        background: #efefef;
        z-index: 9;

        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.56);
    }
}
