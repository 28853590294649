.DeviceBatteryNetworkState {
    display: flex;
    flex-direction: row;
    --containerPaddingTop: 6px;
    padding-top: var(----containerPaddingTop);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-left: 6px;
    margin-right: 6px;
    //height: 5.6rem;
    border-radius: 400px;
    border: solid 1px rgba(0, 0, 0, 0.34);
    .DeviceBatteryNetworkState__divider {
        width: 0px;
        border-right: solid 1px rgba(0, 0, 0, 0.34);
        margin-top: var(----containerPaddingTop);
    }
    .DeviceBatteryNetworkState__smallSpacer {
        max-width: 10px;
        min-width: 2px;
        flex-grow: 1;
    }
    .DeviceBatteryNetworkState__stackedItem {
        margin-left: 7px;
        margin-right: 7px;
        min-width: 2.8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .DeviceBatteryNetworkState__Title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
    }
    .MuiIconButton-sizeMedium {
        width: 40px;
        height: 40px;
        .ValveStateIcon {
            width: 1em;
            height: 1em;
        }
    }
}
.m .DeviceBatteryNetworkState .DeviceBatteryNetworkState__Title {
    display: none;
}
.m .DeviceBatteryNetworkState .DeviceBatteryNetworkState__smallSpacer.titleRelated {
    display: none;
}
.m .DeviceBatteryNetworkState .DeviceBatteryNetworkState__divider.titleRelated {
    display: none;
}
