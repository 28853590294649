.LoadingSpinner {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 100000;
    background-color: white;
    &.LoadingSpinner--doneLoading {
        left: -20rem;
        transition: left 500ms 200ms;
    }
    transition: left 500ms;
    display: flex;
    flex-direction: row;
    min-height: 3rem;
    border-radius: 0.5rem;
    //border: solid gray;
    justify-items: stretch;
    justify-content: space-between;
    box-shadow: 1px 1px 5px 0px black;
    padding: 8px;
    padding-left: 12px;
    height: fit-content;
    color: rgba(0, 0, 0, 0.75);
    h4 {
        color: rgba(0, 0, 0, 0.82);
        min-width: 8rem;
    }
    > div.SpinnerSVGWrapper {
        height: 100%;
        width: fit-content;
        min-width: 4rem;
        #eqgSEWsQAxF1 {
            height: 100%;
            display: block;
            right: 0;
            top: 0;
            position: absolute;
        }
    }
}
.m .LoadingSpinner {
    border-radius: 50%;
    width: 3rem;
    min-width: 3rem;
    .LoadingSpinnerText {
        display: none;
    }
}
