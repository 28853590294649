.SproutAutomationConfigWindow {
    > div > .MuiFormControl-root,
    .SelectAutomationSensor {
        margin: 0.5rem;
    }
    .AutomationSettings {
        margin-left: 1.5rem;
        padding-left: 0rem;
        border-left: solid rgba(0, 0, 0, 0.22) 5px;
    }
    .AutomationSettings > div {
        margin: 1rem;
    }
    .NumericFieldWithUnit {
    }
    .DutyCycleSettings {
        //margin: 0.5rem;
        display: flex;
        flex-direction: row;
        > div {
            margin-right: 1rem;
        }
    }
    .ErrorText {
        color: var(--errorNormal);
    }
}
