.GraphLegendChip {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    border: solid rgba(0, 0, 0, 0.27) 1px;
    border-radius: 100px;
    padding-left: 3px;
    height: 1.5rem;
    padding-right: 8px;
    margin-top: 5px;

    .MuiTypography-root {
        font-size: 0.8rem;
    }

    .LegendIcon {
        margin-right: 4px;
        margin-top: 3px;
        margin-bottom: 3px;
        margin-left: 3px;
        width: 16px;
        height: 16px;

        &.NDVI {
            --legend-color: #53be2c;
        }

        &.Set {
            --legend-color: #ad4ac3;
        }

        &.Irrigation {
            --legend-color: #0077c2;
        }

        &.ScheduledIrrigation {
            --legend-color: var(--scheduled-irrigation);
        }

        &.Dot {
            border-radius: 40px;
            background-color: var(--legend-color);
            border: solid white 4px;
        }

        &.Line {
            background: linear-gradient(
                90deg,
                transparent,
                transparent calc(50% - 2px),
                var(--legend-color) calc(50% - 1px),
                var(--legend-color) calc(50% + 1px),
                transparent calc(50% + 2px),
                transparent
            );
            box-shadow: 0px 0px 0px -6px var(--legend-color);
            position: relative;

            &::after {
                content: " ";
                left: calc(50% - 4px);
                top: calc(50% - 4px);
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 100px;
                background-color: white;
                border: solid 2px var(--legend-color);
            }
        }

        &.Square {
            border-radius: 5px;
            background-color: var(--legend-color);
        }
    }
}
