.StaticZoneGraphTimeRangeDropdown {
    min-width: 8rem;
    //position: absolute;
    right: 0;

    .StaticZoneGraphTimeRangeSelect {
        max-height: 2rem;
        line-height: 1rem;
        padding: 1rem 0.5rem 0.5rem 0;
    }
}
