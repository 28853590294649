.VerificationInfoCardLine {
    display: flex;
    flex-direction: column;
    margin-top: 0.9rem;

    .VerificationInfoCardTitle {
        display: flex;
        width: 100%;
        align-items: center;

        .VerificationInfoHelpModalIcon {
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .IconContainer {
        display: flex;
        align-items: center;
    }

    .ValveOpenAndCloseVerifyContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 2rem;

        .ValveVerifyContainer {
            display: flex;
            padding-left: 56px;
            margin-bottom: 1.5rem;
            flex-direction: column;

            .ValveVerifyDetails {
                display: flex;
                flex-wrap: wrap;

                .ValveTitleText {
                    display: flex;
                    margin-right: 0.8rem;
                }

                .ValveLastVerifiedText {
                    color: rgba(0, 0, 0, 0.6);
                    display: flex;
                    align-items: center;
                }
            }

            .ValveVerificationsChipArray {
                display: flex;
                align-items: center;
                padding-top: 0.2rem;

                .SuccessChipStyle {
                    color: rgba(0, 0, 0, 0.6);
                    height: 1.9rem;
                }

                .FailedChipStyle {
                    border-color: var(--error-icon-main);
                    border-width: 1.5px;
                    height: 1.9rem;
                }
            }
        }
    }

    .ValveOpenAndCloseVerifyContainer.Mobile {
        flex-direction: column;

        .ValveVerifyContainer {
            width: 100%;
            align-items: flex-start;
            padding-right: 0;
            padding-left: 3.5rem;
            margin-bottom: 1rem;
            border-right: none;
        }

        .ValveVerifyRight {
            width: 100%;
            padding-left: 3.5rem;
        }
    }
}
