div.Override.AgendaDateAvatar {
    /* var(--gthemeColor, green);*/
    border-color: var(--palette-main, green);
    border-width: 2px;
    border-style: solid;
    background: transparent;
    color: black;
    text-align: center;
    overflow: visible;
    font-size: 0.8rem;
    margin-bottom: 0;
    padding: 0;
    display: block;
    width: 2.8rem;
    height: 3.2rem;
    &.AgendaDateAvatar--timeIncluded {
        border-bottom-width: 1rem;
    }
}
div.Override.AgendaDateAvatar > p:nth-child(1) {
    font-size: 0.6rem;
    margin-bottom: 0;
    padding: 0;
}
div.Override.AgendaDateAvatar > p:nth-child(2) {
    position: absolute;
    font-size: 0.95rem;
    top: 50%;
    transform: translate(-50%, -20%);
    margin-bottom: 0;
    padding: 0;
    left: 50%;
}
div.Override.AgendaDateAvatar > .AgendaAvatarTime {
    position: absolute;
    font-size: 0.57rem;
    white-space: nowrap;
    top: 100%;
    transform: translate(-50%, -0%);
    color: white;
    margin-bottom: 0;
    padding: 0;
    left: 50%;
}
