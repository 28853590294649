.TextFieldAlignmentOverride--right .MuiFormLabel-root.MuiInputLabel-shrink {
    right: 0;
    left: unset;
    transform: translate(-12px, 7px) scale(0.75);
    /*transform: translate(-5px, -9px) scale(0.75);*/
}
.TextFieldAlignmentOverride--right .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-outlined {
    transform: translate(-14px, -9px) scale(0.75);
    /*transform: translate(-5px, -9px) scale(0.75);*/
}
.TextFieldAlignmentOverride--right .MuiFormLabel-root {
    right: 0;
    left: unset;
    transform: translate(-12px, 16px) scale(1);
    transform-origin: top right;
    /*transform: translate(-5px, -9px) scale(0.75);*/
}
.TextFieldAlignmentOverride--right .MuiInputBase-input {
    text-align: right;
    /*transform: translate(-5px, -9px) scale(0.75);*/
}

.TextFieldAlignmentOverride--right .MuiOutlinedInput-root fieldset legend {
    margin-left: auto;
}
