.error-page {
    &__container {
        min-height: calc(100vh - var(--nav-bar-height));
        padding: 2rem;
        flex-direction: column; 
        text-align: center;
        display:flex;
        align-items: center;
        justify-content: center;
        @media (max-height: 600px) {
            padding: 0.5rem; 
        }


    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 2rem;
        background: white;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        max-width: 800px;
        width: 100%;
        max-height: 100%;
        @media (max-width: 600px) {
        padding: 8px; 
        max-width: 95%; 
    }

    @media (max-height: 600px) {
        padding: 2px; 
        max-width: 95%; 
        
    }
    }
    &__title{
       
            color: rgba(0,0,0,0.87);
            font-size: 6rem;
            font-weight: 700;
            margin-bottom: 2px;
            @media (max-width: 600px) {
                font-size: 3rem; // Make the title responsive
                font-weight: 500;
            }

            @media (max-height: 600px) {
                font-size: 2rem;
                font-weight: 500; 
            }
    }

    &__illustration {
        max-width: 300px;
        height: auto;
        margin: 1rem 0;
        object-fit: contain;
        @media (max-width: 800px) {
        max-height: 30vh; // Make the illustration responsive
        margin: 0.5rem 0;
    }
    @media (max-height: 600px) {
        max-height: 30vh; // Make the illustration responsive
        margin: 0.5rem 0;
    }
    }


    &__header {
        color: rgba(0, 0, 0, 0.87);
        font-size: 1.75rem;
        font-weight: 500;
        margin-top: 3rem;
        text-align: center;
        @media (max-height: 800px) {
            margin-bottom: 0rem;
            margin-top: 0rem;
        }
    }
    
    &__body {
        color: rgba(0, 0, 0, 0.6);
        margin-top: 1rem;
        margin-bottom: 4rem;
        text-align: center;
        max-width: 600px;
        @media (max-height:800px)
        {
            margin-bottom: 0rem;
            margin-top: 0rem;
        }
        @media (max-width: 600px) {
            margin-bottom: 0rem;
        }
        
    }

    &__actions {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;

        @media (max-width: 600px) {
            flex-direction: column;
            width: 100%;
        }
    }

    &__button {
        min-width: 160px;

        @media (max-width: 600px) {
            width: 100%;
        }
        @media (max-height:600px)
        {
            margin-bottom: 0rem;
            margin-top: 0rem;
        }
    }

}
