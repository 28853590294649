.HelpImageBox {
    max-width: 100%;
    max-width: calc(100% - 10px);
    padding: 5px;
    margin-left: 5px;
}

.HelpImageBox img {
    max-width: calc(100%);
}

.StepNumber {
    padding-top: 0.2rem;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--palette-main);
    color: white;
    display: inline-block;
    text-align: center;
    vertical-align: baseline;
}

.HelpText h6 {
    padding-top: 14px;
    padding-bottom: 4px;
}

.StepDetails {
    padding-top: 10px;
    padding-bottom: 10px;
}
