.StaticTrendGraphChipArray {
    margin-top: 1rem;
    display: inline-block;
    overflow-y: hidden;
    vertical-align: middle;
    white-space: nowrap;
    padding-right: 10px;
    padding-bottom: 20px;
}

.TrendGraphChips {
    box-shadow: 0px 0px 5px inset #0000004f;
    position: relative;
    border-top: 3px solid #00000094;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgba(0, 0, 0, 0.02);

    &.TrendGraphChips--row {
        display: flex;
        flex-direction: row;
    }
    &.TrendGraphChips--showArrows {
        .ArrowScroll {
            transform: scaleX(1);
            max-width: 3rem;
            transition: 0.6s ease;
            flex-grow: 0;
        }
    }
    .ArrowScroll {
        max-width: 0;
        transform: scaleX(0);
        padding: 0px;
        transition: 0.6s ease;
        flex-grow: 0;
        border-radius: 0 10px 10px 0;
        background-color: white;
        margin-top: 10px;
        margin-bottom: 10px;
        height: calc(100% - 20px);
    }
    .ArrowScroll-left {
        position: absolute;
        top: 0;
        left: -5px;
        border-radius: 0px 10px 10px 0px;
        box-shadow: 0px 0px 5px #0000004f;
        &::after {
            content: " ";
            width: 0;
            height: 0;
            transform: scale(0.5);
            transform-origin: bottom left;
            border-top: 5px solid transparent;
            border-bottom: 5px solid rgba(0, 0, 0, 0.25);
            border-right: 5px solid rgba(0, 0, 0, 0.25);
            border-left: 5px solid transparent;
            top: -10px;
            left: 0px;
            position: absolute;
        }
    }
    .ArrowScroll-right {
        position: absolute;
        top: 0;
        right: -5px;
        box-shadow: 0px 0px 5px #0000004f;
        border-radius: 10px 0px 0px 10px;
        &::after {
            content: " ";
            width: 0;
            height: 0;
            transform: scale(0.5);

            transform-origin: bottom right;
            border-top: 5px solid transparent;
            border-bottom: 5px solid rgba(0, 0, 0, 0.25);
            border-right: 5px solid transparent;
            border-left: 5px solid rgba(0, 0, 0, 0.25);
            top: -10px;
            right: 0px;
            position: absolute;
        }
    }
    .ArrowScroll-padding {
        background-color: transparent;
        color: transparent;
        user-select: none;
    }
    .HorizontalScroll {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100%;
        max-width: 100%;
        position: relative;
        display: flex;
        transition: 0.6s ease;
    }
}
