.PacketLossScoresContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.PacketLossScoresPaddingDiv {
    flex: 1;
}
.PacketLossScoresContainer .PacketLossScore {
    flex-shrink: 0;
    border-radius: 50%;
    height: 20vw;
    width: 20vw;
    max-height: 100px;
    max-width: 100px;
    padding: 5px;
    padding-top: 10px;
    margin: 5px;
    text-align: center;
    color: white;
}

.DevicePositionSubheader {
    display: flex;
    flex-direction: row;
}
.DevicePositionSubheader .PositionSubheaderText {
    flex-grow: 100;
    white-space: nowrap;
    max-width: 15rem;
    min-width: 0;
    flex-shrink: 10;
}
.DevicePositionSubheader > button {
    margin: 7px;
}
.DevicePositionSubheader .RepositionButton {
    padding-top: 0.3rem;
    flex-wrap: wrap;
    overflow: hidden;
    transition:
        background-color 100ms ease-in-out,
        border-radius 100ms ease-in-out;
    padding-bottom: 0.3rem;
    align-items: center;
    align-content: center;
    min-width: fit-content;
    &.RepositionButton--mini.RepositionButton--not-relocating {
        border-radius: 20px;
        .MuiButton-startIcon {
            margin-right: -4px;
            margin-left: -4px;
        }
    }
}

.DevicePositionSubheader--repositioning .RepositionButton {
    background-color: var(--palette-main);
}
.DevicePositionSubheader--repositioning .RepositionButton:hover {
    background-color: var(--palette-dark);
}
.DevicePositionSubheader .cancelButton {
    background-color: #b00020;
    color: #ffffffbb;
}
.DevicePositionSubheader .cancelButton:hover {
    background-color: #980023;
}
.DevicePositionSubheader--idle .cancelButton {
    max-width: 0px;
    flex: 0;
    flex-grow: 0;
    min-width: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    transform: scaleX(0);
}
.DevicePositionSubheader .DevSubheaderIDField {
    font-family: "Roboto Condensed";
    font-size: 12px;
}
.DevicePositionSubheader--repositioning .cancelButton {
    min-width: 0;
    flex: 0;
    flex-grow: 0;
    transform: scaleX(1);
    transition:
        max-width 150ms ease-in-out,
        padding 150ms ease-in-out,
        margin 150ms ease-in-out,
        transform 150ms ease-in-out,
        background-color 100ms ease-in-out;
}
@media only screen and (max-width: 400px) {
    .DevicePositionSubheader > p {
        font-size: 12px;
        line-height: 1.75;
    }
}
.InfoCardHeader .WarningText {
    cursor: pointer;
    background-color: #fb8c00;
    border-radius: 3px;
    color: white;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
}
.InfoCardHeader .WarningText.WarningText--error {
    background-color: red;
    border-radius: 3px;
    color: white;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
}

/*.DeviceCardStatusEntry{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    width:100%;*/
/*    text-align: center;*/
/*    padding-bottom: 20px;*/
/*}*/
/*.DeviceCardStatusEntry_Left{*/
/*    flex-grow: 1;*/
/*    text-align: right;*/

/*}*/
/*.DeviceCardStatusEntry_Center{*/
/*    padding-left: 6px;*/
/*    padding-right:6px;*/
/*    flex-grow: 0.3;*/

/*}*/
/*.DeviceCardStatusEntry_Right{*/
/*    flex-grow: 1;*/
/*    text-align: left;*/

/*}*/
