.YaxisTabs {
    position: absolute;
    height: 100%;
    width: 30px;
    right: calc(100% + 32px);
    pointer-events: all;
    user-select: none;
    .YAxisTabsDecrotaivePipe {
        height: 100%;
        right: 0;
        position: absolute;
        border-left: solid grey 2px;
        z-index: 0 !important;
    }
    .YaxisSingleTab {
        cursor: pointer;
        right: 0;
        position: absolute;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        font-size: 0.8rem;
        color: rgba(92, 92, 92, 0.8);
        font-family: Roboto;
        font-weight: 400;
        text-align: center;
        transition:
            top 300ms ease-out,
            height 300ms ease-out,
            color 300ms ease-in-out,
            z-index 0ms 0ms ease-in-out,
            opacity 150ms ease-in-out,
            border-right 150ms ease-in-out,
            border-top 150ms ease-in-out,
            border-bottom 150ms ease-in-out;
        background: #e8e8e8;
        border: solid grey 2px;
        box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 0px 6px 6px 0px;
        &.YaxisSingleTab--hidden {
            height: 0 !important;
            color: rgba(92, 92, 92, 0);
            opacity: 0;
            //border: solid grey 0px;
        }
        &.YaxisSingleTab--unselected {
            border-right: none;
            border-top: none;
            border-bottom: none;
            &:hover {
                box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
                background: #f3f3f3;
            }
        }

        &.YaxisSingleTab--selected {
            z-index: 251 !important;
            border-left: none;
            background: white;
        }
    }
    .ZoomOnYSwitch {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        transform: translate(0, 100%);
        width: 100%;
    }
}
.YAxisTabExpandedOptionsList {
    .MuiListItemIcon-root {
        min-width: 30px;
    }
    .YAxisTabExtraSelectUnit {
        min-width: 40px;
        display: inline-block;
        font-weight: 600;
        text-align: center;
    }
}
