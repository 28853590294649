iframe {
    display: none !important;
}

html {
    width: 100%;
    height: 100%;
}
@media (-webkit-device-pixel-ratio: 1.5) {
    html {
        font-size: 12px;
    }
    body #root {
        --nav-bar-height: 35px;
    }
}
@media (-webkit-device-pixel-ratio: 1.25) {
    html {
        font-size: 14px;
    }
    body #root {
        --nav-bar-height: 45px;
    }
}
@media (-webkit-device-pixel-ratio: 2) {
    html {
        font-size: 12px;
    }
    body #root {
        --nav-bar-height: 35px;
    }
}

@media (-webkit-device-pixel-ratio: 2.5) {
    html {
        font-size: 12px;
    }
    body #root {
        --nav-bar-height: 35px;
    }
}

body {
    --nav-bar-height: 55px;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#main {
    width: 100%;
    height: 100%;
}

#map {
    /* leaflet only */
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #808080;
    top: var(--nav-bar-height) !important;
    z-index: 0;
}
.MuiButtonBase-root.MuiChip-root.MuiChip-sizeMedium {
    height: 1.8rem;
    .MuiAvatar-root.MuiAvatar-circular.MuiChip-avatar.MuiChip-avatarMedium {
        width: 1.4rem;
        height: 1.4rem;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0.3rem;
    }
}

.nav-item {
    margin-left: 40px;
    font-size: 19px;
}

#map-options {
    border: none;
    z-index: 10000;
    position: absolute;
    margin-left: 1700px;
    margin-top: -1000px;
    height: 59px;
    background-color: #fff;
    border-radius: 3px;
}
