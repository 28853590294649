.fullsizeZoneOverview {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.fullsizeZoneOverview .headerContainer {
    display: flex;
    min-height: 55px;
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--palette-text);
    padding-bottom: 5px;
    flex-grow: 0;
    position: relative;
}

.fullsizeZoneOverview.fullsizeZoneOverview--notInZoneCard .headerContainer {
    background-color: var(--palette-main);
    color: rgba(255, 255, 255, 0.9);
    border-radius: 20px 20px 0 0;
    min-width: 24rem;
}

.fullsizeZoneOverview .varietalAvatarContainer {
    padding: 9px 7px 9px 7px;
}

.fullsizeZoneOverview .varietalAvatar {
    font-size: 1.2rem;
    width: 40px;
    height: 40px;
}

.fullsizeZoneOverview .headerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fullsizeZoneOverview .statusChips {
    display: flex;
}

.fullsizeZoneOverview.fullsizeZoneOverview--notInZoneCard .statusChips .zoneOverviewStatusChipStyle {
    color: var(--palette-text);
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
}

.fullsizeZoneOverview .headerContainer::after {
    content: " ";
    position: absolute;
    top: 100%;
    width: 100%;
    height: 1px;
    background: linear-gradient(rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0));
    z-index: 1;
}

.fullsizeZoneOverview.fullsizeZoneOverview--notInZoneCard .headerContainer::after {
    height: 6px;
}

.fullsizeZoneOverview .condensedContainer {
    display: flex;
    justify-content: space-around;
    position: relative;
    align-content: stretch;
    align-items: stretch;
    flex-direction: row-reverse;
}

.fullsizeZoneOverview .currentValuesContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    position: relative;
    align-self: stretch;
}

.fullsizeZoneOverview .condensedContainer .currentValuesContainer {
    flex-direction: column;
}
.fullsizeZoneOverview .condensedContainer .currentValuesContainer > div {
    width: 100%;
}

.fullsizeZoneOverview .currentValuesContainer > div {
    min-height: 45px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.2rem;
}

.fullsizeZoneOverview .condensedContainer .currentValuesContainer > div {
    /*margin-top: 8px;*/
    max-height: 150px;
}

.fullsizeZoneOverview .ageContainer {
    color: rgba(255, 255, 255, 0.9);
    background: var(--palette-main);
    padding: 0.2rem;
    flex-direction: column;
}

.fullsizeZoneOverview .condensedContainer .ageContainer {
    width: 100%;
}

.fullsizeZoneOverview .ageContainer .ageValueText {
    font-size: 1rem;
    line-height: 1.2;
}

.fullsizeZoneOverview .ageContainer .ageText {
    font-size: 0.8rem;
    line-height: 1;
}

.fullsizeZoneOverview .cloneContainer {
    color: rgba(255, 255, 255, 0.9);
    background: var(--palette-main);
    flex-direction: column;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.fullsizeZoneOverview .cloneContainer .cloneText {
    font-size: 0.875rem;
    line-height: 1.4;
}

.fullsizeZoneOverview .cloneContainer .cloneDivider {
    border-color: rgba(255, 255, 255, 0.9);
}

.fullsizeZoneOverview .depthProfileContainer {
    position: relative;
    display: flex;
    flex-direction: column;
}

.fullsizeZoneOverview .condensedContainer .depthProfileContainer {
    flex-grow: 1;
    margin-right: 20px;
}

.fullsizeZoneOverview .depthProfileContainer .depthProfile {
    flex-grow: 1;
    border-radius: 8px;
    min-height: 151px;
}

.fullsizeZoneOverview .soilProfile .soilBar > p {
    text-align: left;
    width: 80px;
    top: 50%;
    transform: translate(0, -50%);
    position: relative;
}

.fullsizeZoneOverview .staticGraphContainer {
    position: relative;
    padding-top: 10px;
}
.fullsizeZoneOverview .FullsizeZoneOverviewBody {
    padding-left: 1rem;
}
.fullsizeZoneOverview.fullsizeZoneOverview--uncondensedChars .FullsizeZoneOverviewBody {
    padding-top: 10px;
}

.soilProfileWrapper {
    padding-left: 24px;
}
