.MicrovalveStatusRoot {
    position: relative;
    width: 100%;
    text-align: center;
}
.MicrovalveStatusRoot .MicrovalveStatusSpacer {
    min-height: 1.4rem;
}
.MicrovalveStatusBody div.SensorSelectDropdown {
    background-color: #ccfff31a;
    padding-top: 0;
    margin-top: 7px;
    backdrop-filter: blur(5px);
}
.MicrovalveStatusBody
    .MicrovalveStatusPanel
    > :first-child
    .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-outlined {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 2px;
}

.MicrovalveStatusBody .MicrovalveStatusPanel > :first-child .MuiFormLabel-root.MuiInputLabel-outlined {
    background-color: rgba(255, 255, 255, 0);
    transition:
        color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        background-color 200ms ease-in-out;
}

.MicrovalveStatusBody div.SensorSelectDropdownRoot {
    position: relative;
}
.MicrovalveStatusImageBackground {
    position: absolute;
    width: 70%;
    opacity: 0.15;
    bottom: 5rem;
    left: 50%;
    transform: translate(-50%, 50%);
    pointer-events: none;
}
.MicrovalveStatusBody {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    max-width: 100%;
}
.MicrovalveStatusPanelEdge {
    min-width: 5px;
    max-width: 20px;
    flex-shrink: 4;
}
.MicrovalveStatusPanel {
    flex-shrink: 1;
    min-width: 2rem;
    width: 10rem;
    flex-grow: 3;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
}
.MicrovalveStatusPanel > :first-child .MuiInputBase-root {
    background-color: #cbd9d945;
    /* background: unset; */
    backdrop-filter: blur(5px);
}

.MicrovalveStatusPanelEdge__activity {
    min-width: 5px;
    max-width: 20px;
    flex-grow: 1;
    flex-shrink: 3;
}
.MicrovalveStatusPanelActiveEdge--active {
    background-color: var(--palette-main);
}
.MicrovalveStatusActiveValve--active {
    background-color: rgba(190, 246, 122, 0.21);
    /*background-color: rgba(139, 195, 74, 0.21);*/
}

/*.MicrovalveStatusPanelActiveEdge--active::after{*/
/*    content:"";*/
/*    background-color: var(--palette-main);*/
/*    height:100%;*/
/*    width:5px;*/
/*}*/

.MicrovalveStatusPanelEdge__Details {
    min-width: 1.5rem;
    border-radius: 10px;
}

.MicrovalveStatusPanelEdgeAlign--right.MicrovalveStatusPanelEdge__Details {
    border-radius: 0px 10px 10px 0px;
}
.MicrovalveStatusPanelEdgeAlign--left.MicrovalveStatusPanelEdge__Details {
    border-radius: 10px 0px 0px 10px;
}
.MicrovalveStatusPanel > :first-child::after {
    content: var(--valveLabel);
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%) translateX(-2px);
}
.MicrovalveStatusPanel.MicrovalveStatusPanelAlign--right > :first-child::after {
    right: 0;
    transform: translate(100%, -50%) translateX(2px);
}

.MicrovalveStatusPanel > :nth-child(2)::after {
    content: var(--sensorLabel);
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%) translateX(-2px);
}
.MicrovalveStatusPanel.MicrovalveStatusPanelAlign--right > :nth-child(2)::after {
    right: 0;
    transform: translate(100%, -50%) translateX(2px);
}
.MicrovalveZoneSelection .MicrovalveZoneSelectHelp {
    cursor: pointer;
}

.DeviceCardStatusRoot {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
}
.DeviceCardStatusRoot .DeviceCardStatusBody {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
}
div.DeviceCardStatusEntry.DeviceCardStatusEntry_Center {
    padding-left: 6px;
    padding-right: 6px;
    flex-grow: 0.3;
    text-align: center;
}

.DeviceCardStatusRoot .DeviceCardStatusEntry:first-child {
    width: 100px;
    flex-grow: 1;
    text-align: right;
}
.DeviceCardStatusEntry:first-child div.DeviceStatusZoneInput--rightAligned > fieldset {
    text-align: right;
}
.DeviceCardStatusEntry > div {
    margin-top: 6px;
}
/*.DeviceCardStatusEntry:first-child  .MuiInputLabel-formControl{*/
/*    left:unset;*/
/*    right: 0;*/
/*}*/
div.DeviceCardStatusEntry {
    display: flow-root;
    text-align: left;
    width: 100px;
    flex-grow: 1;
    padding: 5px 0 5px 0;
}
div.DeviceCardStatusEntry button {
    margin-top: 10px;
}

div.DeviceCardStatusEntry .DevLabelContainer {
    position: relative;
}
.DeviceCardStatusRoot div.SensorSelectDropdown {
    min-width: 100%;
    padding-top: 5px;
}

.DeviceCardStatusRoot div.SensorSelectDropdown label.Mui-focused.Mui-focused {
    color: rgba(0, 0, 0, 0.89);
}
