.SproutVerificationCheck {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5) 10px, transparent 10px, transparent);
    .SproutVerificationCheck__AccordianHeader {
        padding-left: 1rem;
        display: flex;
        flex-direction: row;

        border-top: solid 2px rgba(0, 0, 0, 0.39);
        .SproutVerificationCheck__AccordianHeaderStatus {
            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 10px 0px 0px 10px;
            transform: translateX(2px);
            box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.39);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            padding-left: 4px;
            padding-right: 4px;
            width: 8ch;
            max-width: 8ch;
            min-width: 8ch;
            .VerificationStatusIcon {
                box-shadow: none;
                background-color: none;
                background: unset;
            }
        }
        .SproutVerificationCheck__AccordianHeaderDetails {
            .UpdatedAt {
                font-style: italic;
                color: rgba(0, 0, 0, 0.63);
                font-size: 0.8rem;
                margin-left: 5px;
            }
            //border-top: solid 2px rgba(0, 0, 0, 0.39);
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 4px;
            padding-bottom: 4px;
            flex-grow: 10;
        }
    }
    &:last-child {
        .SproutVerificationCheck__AccordianHeader {
            //.SproutVerificationCheck__AccordianHeaderDetails{
            //    border-bottom: solid 2px rgba(0, 0, 0, 0.39);
            //}
        }
    }
}
