.DeviceNetworkTest {
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}
.DeviceNetworkTestDialog {
    .LoadingButton {
        svg.MuiSvgIcon-root {
            animation: rotating 2s linear infinite;
        }
    }
    .WarningSection {
        background: #ff00001c;
        padding-left: 13px;
        margin-left: -13px;
        margin-right: -13px;
        padding-right: 13px;
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 5px;
        border: solid #71000070 2px;
    }
}
.DeviceNetworkTest {
    .LoadingButton {
        svg.MuiSvgIcon-root {
            animation: rotating 2s linear infinite;
        }
    }
}
