@keyframes FirstLoad {
    from {
        max-height: 0;
        min-height: 0;
        transform: scaleX(0.9);
    }
}

.InfoPane_CardHolder {
    position: fixed;
    width: 100%;
    z-index: 800;
    right: 0;
    bottom: 0;
    --header-height: 0px;
    max-width: 500px;
    animation: FirstLoad 500ms ease-in-out;
    animation-fill-mode: backwards;
    transition:
        max-height 400ms ease-out,
        transform 300ms 100ms ease-in-out,
        min-height 400ms ease-out,
        height 400ms ease-out;
    .InfoCardHeader > .MuiCardHeader-action {
        margin-top: 0;
        margin-bottom: 0;
    }
}
.InfoPane_CardHolder.InfoPane_CardHolder--visible {
    transform: scaleX(1);
}
.InfoPaneBody {
    padding-top: 0 !important;
    max-height: var(--maxInfoPaneHeight);
    min-height: var(--minInfoPaneHeight, 250px);
    padding-bottom: 100px;

    transition:
        overflow 0ms 300ms,
        max-height 300ms 300ms,
        min-height 300ms 200ms;
}
@media only screen and (max-width: 500px) {
    div.InfoPaneBody {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.InfoPaneBody.InfoPaneBody--nativeScroll {
    overflow: auto;
}

.fullScreenActivate {
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    margin-right: 0 !important;
    bottom: 0px;
    position: fixed;
    z-index: 800;
    right: 0;
}
/*.InfoPaneBody--unloaded {*/
/*  max-height: 0;*/
/*  min-height: 0px;*/
/*}*/
.InfoPane_CardHolder.InfoPane_CardHolder--hidden .InfoPaneBody--unloaded {
    max-height: var(--minInfoPaneHeight, 250px);
    min-height: var(--minInfoPaneHeight, 250px);
}
.InfoCardMainCard > div:first-child {
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: black 0px 0px 4px;
    z-index: 10;
    position: relative;
    /*min-height: 40px;*/
}
.InfoCardMainCard > div:first-child > .MuiCardHeader-content {
    width: 100px;
    flex-shrink: 10;
    flex-grow: 10;
}

// Prevent horizontal scrolling on device cards
// override the "overflow-x: scroll" set by simplebar
.InfoCardMainCard .InfoPaneBody .simplebar-content-wrapper {
    overflow-x: hidden !important;
}

.AdvancedSchedulingPanel.InfoPane_CardHolder.InfoPane_CardHolder--hidden {
    transform: scaleX(0.98);
}
.InfoPane_CardHolder.InfoPane_CardHolder--hidden {
    transform: scaleX(0.9);
    max-height: 0;
    min-height: 0;
    z-index: 600;
}
@media only screen and (max-width: 500px) {
    div.InfoPane_CardHolder {
        margin-right: 0 !important;
    }
}

@media only screen and (min-width: 400px) {
    div.InfoPane_CardHolder {
        margin-right: 10px;
    }
    /*div.InfoPane_CardHolder.InfoPane_CardHolder--visible {*/
    /*  max-height: calc(80vh);*/
    /*}*/
    /*.InfoPane_CardHolder > div.InfoCardMainCard {*/
    /*  max-height: calc(80vh);*/
    /*}*/
}

@keyframes bounceLoadInfoCard {
    0% {
        max-height: 0px;
        min-height: 0px;
    }
    50% {
        max-height: calc(var(--maxInfoPaneHeight) + var(--header-height, 400px));
        min-height: calc(var(--maxInfoPaneHeight) + var(--header-height, 0px));
    }
    100% {
        max-height: calc(var(--maxInfoPaneHeight) + var(--header-height, 400px));
        min-height: calc(var(--minInfoPaneHeight, 0px) + var(--header-height, 0px));
    }
}
.InfoPane_CardHolder--bounceLoad.InfoPane_CardHolder.InfoPane_CardHolder--visible {
    transform: scaleX(1);
    max-height: calc(var(--maxInfoPaneHeight) + var(--header-height, 400px));
    min-height: calc(var(--minInfoPaneHeight, 250px) + var(--header-height, 400px));
}

.InfoPane_CardHolder.InfoPane_CardHolder--visible {
    max-height: calc(var(--maxInfoPaneHeight) + var(--header-height, 400px));
    min-height: calc(var(--minInfoPaneHeight, 250px) + var(--header-height, 400px));
}
.InfoPane_CardHolder.InfoPane_CardHolder--hidden > .InfoPanelTray {
    transform: translate(0, 0);
}
.InfoPane_CardHolder > .InfoPanelTray {
    transform: translate(0, -100%);
    position: absolute;
    width: 100%;
    transition: transform 300ms;
    z-index: -1;
}

.InfoPane_CardHolder--bounceLoad.InfoPane_CardHolder.InfoPane_CardHolder--visible > .InfoCardMainCard {
    //animation-name: bounceLoadInfoCard !important;
    //animation-timing-function: ease-in-out;
    //animation-duration: 650ms;
    //animation-fill-mode: both;
    //max-height: calc(var(--maxInfoPaneHeight) + var(--header-height, 400px));
    //min-height: calc(var(--minInfoPaneHeight, 250px) + var(--header-height, 400px));
}
.InfoPane_CardHolder--bounceLoad.InfoPane_CardHolder.InfoPane_CardHolder--hidden > .InfoCardMainCard {
    //max-height: 0px;
    //min-height: 0px;
}

.InfoPane_CardHolder--bounceLoad.InfoPane_CardHolder > .InfoCardMainCard {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1000;
    display: flex;
    flex-flow: column;
    transition:
        height 270ms ease-in-out,
        max-height 270ms ease-in-out,
        min-height 270ms ease-in-out;
}

.InfoPanel_HideButton {
    transition:
        -webkit-transform 400ms,
        transform 400ms !important;
}
.InfoCardMainCard--visible .InfoPanel_HideButton {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.InfoPane_CardHolder--interacted > div {
    animation: 200ms ease-in 0s 2 alternate both GetFocus;
}

.InfoPane_detailList {
    padding-top: 0px;
    overflow-x: clip;
    li {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.InfoPane_CardHolder--interacted > div {
    animation: 200ms ease-in 0s 2 alternate both GetFocus;
}
.InfoPane_CardHolder--interacted > .InfoPanelTray {
    animation: unset;
}

@keyframes GetFocus {
    from {
        transform: translate(0px) scale(1);
        background-color: #ffffff;
    }
    to {
        transform: translate(0px, -10px) scale(1.04);
        background-color: #eaeaea;
    }
}

.InfoPane_CardHolder--firstRender {
}
