.MarkerIcon .MarkerIconRim {
    transform: var(--icon-rim-transform, scale(0));
    fill: var(--icon-rim-fill-color, #ffffff);
    transform-origin: 12px 12px;
    transition: transform 200ms ease-in-out;
}
.MarkerIcon .MarkerIconOuterCircle {
    fill: var(--main-color);
    transition: fill 200ms ease-in-out;
}
.MarkerIcon .MarkerIconInnerCircle {
    /*Experimentally determined*/
    transform-origin: 12px 12px;
    transition: transform 200ms ease-out;
}
.leaflet-marker-icon > div::after {
    //content : "sdaf";
    //background-color: blue;
    //width: 10px;
    //height: 10px;
    //
    //--iconBackgroundColor: #b2e3ff;
    //--icon-rim-transform: scale( 1.2);
    //--icon-rim-fill-color: var(--irrigation-dark) !important;
}
.MarkerIcon .AlertBubble {
    transform: scale(0);
    fill: transparent;
}
.ME_ValveOpen.leaflet-marker-icon .MarkerIcon .AlertBubble {
    transform: scale(1);
    fill: var(--irrigation-main);
}
.leaflet-marker-icon .MarkerIcon .AlertBubble {
    transform: scale(0);
    fill: transparent;
}
.leaflet-map-pane {
    --icon-multiplier: 1;
}
.leaflet-map-pane svg.MarkerIcon:hover .MarkerIconInnerCircle {
    transform: scale(0.8);
}
@keyframes popIn {
    0% {
        transform: translate(-50%, -50%) scale(0.001);
    }
}
.leaflet-map-pane svg.MarkerIcon {
    animation: popIn 800ms ease-out;
    transition:
        transform 200ms ease-out,
        width 500ms ease-in-out,
        height 500ms ease-in-out,
        max-width 500ms ease-in-out,
        max-height 500ms ease-in-out;
    transform: translate(-50%, -50%);
    max-width: min(
        calc(var(--pxPerMeter, 5px) * var(--nearestNeighbour, 1000)),
        calc(var(--icon-size, 5px) * var(--icon-multiplier, 1))
    );
    max-height: min(
        calc(var(--pxPerMeter, 5px) * var(--nearestNeighbour, 1000)),
        calc(var(--icon-size, 5px) * var(--icon-multiplier, 1))
    );
    width: calc(var(--icon-size, 5px) * var(--icon-multiplier, 1));
    height: calc(var(--icon-size, 5px) * var(--icon-multiplier, 1));
    top: 0;
    left: 0;
    position: absolute;
}
.leaflet-map-pane svg.MarkerIcon:hover {
    transform: translate(-50%, -50%) scale(1.5);
    width: calc(var(--icon-size, 5px) * var(--icon-multiplier, 1));
    height: calc(var(--icon-size, 5px) * var(--icon-multiplier, 1));
    /*transition: transform 100ms, width 100ms, height 100ms;*/
}

.leaflet-map-pane .DeviceIconComponentRoot--enlarged {
    --icon-multiplier: 1.8;
    /*transition: transform 100ms, width 100ms, height 100ms;*/
}

#root.iconSize--XSmall #map {
    .leaflet-marker-pane.zoom-level__device .custom-div-icon {
        --icon-size: 40px;
    }
    .leaflet-marker-pane.zoom-level__zone .custom-div-icon {
        --icon-size: 24px;
    }
    .leaflet-marker-pane.zoom-level__field .custom-div-icon {
        --icon-size: 14px;
    }
    .leaflet-marker-pane.zoom-level__farm .custom-div-icon {
        --icon-size: 10px;
    }
    .leaflet-marker-pane.zoom-level__region .custom-div-icon {
        --icon-size: 6px;
    }
    .leaflet-marker-pane.zoom-level__world .custom-div-icon {
        --icon-size: 0;
    }
}

#root.iconSize--small #map {
    .leaflet-marker-pane.zoom-level__device .custom-div-icon {
        --icon-size: 60px;
    }
    .leaflet-marker-pane.zoom-level__zone .custom-div-icon {
        --icon-size: 36px;
    }
    .leaflet-marker-pane.zoom-level__field .custom-div-icon {
        --icon-size: 24px;
    }
    .leaflet-marker-pane.zoom-level__farm .custom-div-icon {
        --icon-size: 16px;
    }
    .leaflet-marker-pane.zoom-level__region .custom-div-icon {
        --icon-size: 10px;
    }
    .leaflet-marker-pane.zoom-level__world .custom-div-icon {
        --icon-size: 0;
    }
}

#root #map {
    .leaflet-marker-pane.zoom-level__device .custom-div-icon {
        --icon-size: 68px;
    }
    .leaflet-marker-pane.zoom-level__zone .custom-div-icon {
        --icon-size: 48px;
    }
    .leaflet-marker-pane.zoom-level__field .custom-div-icon {
        --icon-size: 32px;
    }
    .leaflet-marker-pane.zoom-level__farm .custom-div-icon {
        --icon-size: 24px;
    }
    .leaflet-marker-pane.zoom-level__region .custom-div-icon {
        --icon-size: 16px;
    }
    .leaflet-marker-pane.zoom-level__world .custom-div-icon {
        --icon-size: 0;
    }
}

#root.iconSize--large #map {
    .leaflet-marker-pane.zoom-level__device .custom-div-icon {
        --icon-size: 80px;
    }
    .leaflet-marker-pane.zoom-level__zone .custom-div-icon {
        --icon-size: 64px;
    }
    .leaflet-marker-pane.zoom-level__field .custom-div-icon {
        --icon-size: 32px;
    }
    .leaflet-marker-pane.zoom-level__farm .custom-div-icon {
        --icon-size: 24px;
    }
    .leaflet-marker-pane.zoom-level__region .custom-div-icon {
        --icon-size: 16px;
    }
    .leaflet-marker-pane.zoom-level__world .custom-div-icon {
        --icon-size: 0;
    }
}

#root.iconSize--XLarge #map {
    .leaflet-marker-pane.zoom-level__device .custom-div-icon {
        --icon-size: 100px;
    }
    .leaflet-marker-pane.zoom-level__zone .custom-div-icon {
        --icon-size: 86px;
    }
    .leaflet-marker-pane.zoom-level__field .custom-div-icon {
        --icon-size: 64px;
    }
    .leaflet-marker-pane.zoom-level__farm .custom-div-icon {
        --icon-size: 32px;
    }
    .leaflet-marker-pane.zoom-level__region .custom-div-icon {
        --icon-size: 24px;
    }
    .leaflet-marker-pane.zoom-level__world .custom-div-icon {
        --icon-size: 0;
    }
}

.InfoCardAvatar svg.MarkerIcon:hover .MarkerIconInnerCircle {
    transform: scale(1);
}

.InfoCardAvatar svg.MarkerIcon {
    width: 100%;
    height: 100%;
}
