.FiniteStateGraphRoot {
    min-width: 800px;
    position: relative;
    height: 14px;
    .FSGtooltip {
        position: absolute;
        white-space: nowrap;
        transform: translateX(-50%);
        border-radius: 4px;
        bottom: 20px;
        background: white;
        border: solid rgba(17, 17, 17, 0.16) 1px;
        font-size: 0.8rem;
        padding: 4px;
        transition:
            opacity 50ms ease-in-out,
            left 30ms ease-in-out;
        &.FSGtooltip--inactive {
            opacity: 0;
            transition:
                opacity 1500ms ease-in,
                left 30ms ease-in-out;
        }
        .FSGtooltipTime {
            white-space: nowrap;
        }
    }
    .FSGChunkWrapper {
        background-color: rgba(0, 0, 0, 0.11);
        width: 100%;
        height: 14px;
        overflow: hidden;
        position: relative;
    }
    .FiniteStateGraph__Chunk {
        position: absolute;
    }
    .FiniteStateGraph__Chunk--ScheduledValve {
        height: 12px;
        bottom: 2px;
        z-index: 1;
        &.FiniteStateGraph__Chunk--ScheduledValve_open {
            background-color: var(--scheduled-irrigation);
        }
    }
    .FiniteStateGraph__Chunk--ZoneOnlineState {
        height: 12px;
        z-index: 1;
        &.FiniteStateGraph__Chunk--ZoneOnlineState_offline {
            background-color: var(--errorNormal);
        }
        &.FiniteStateGraph__Chunk--ZoneOnlineState_partiallyOffline {
            background-color: var(--warningNormal);
        }
        &.FiniteStateGraph__Chunk--ZoneOnlineState_unknown {
        }
        &.FiniteStateGraph__Chunk--ZoneOnlineState_online {
            background-color: var(--palette-main);
            height: 4px;
        }
    }
    .FiniteStateGraph__Chunk--ActualValve {
        height: 6px;
        bottom: 2px;
        z-index: 5;
        &.FiniteStateGraph__Chunk--ActualValve_open {
            background-color: var(--irrigation-dark);
        }
    }
    .FiniteStateGraph__Chunk--CorrectState {
        height: 2px;
        bottom: 0px;
        z-index: 5;
        &.FiniteStateGraph__Chunk--CorrectState_correct {
            background-color: var(--palette-main);
        }
        &.FiniteStateGraph__Chunk--CorrectState_incorrect {
            background-color: var(--errorNormal);
        }
        &.FiniteStateGraph__Chunk--CorrectState_innaccurate {
            background-color: var(--warningNormal);
        }
        &.FiniteStateGraph__Chunk--CorrectState_slightLate {
            background-color: unset;
        }
    }
    .FiniteStateGraph__Chunk--ZoneOpenPercentageState {
        height: 0px;
        bottom: 0px;
        z-index: 5;
        background-color: var(--irrigation-dark);
        &.FiniteStateGraph__Chunk--ZoneOpenPercentageState_openFull {
            height: 6px;
        }
        &.FiniteStateGraph__Chunk--ZoneOpenPercentageState_mostlyOpen {
            height: 5px;
        }
        &.FiniteStateGraph__Chunk--ZoneOpenPercentageState_partiallyOpen {
            height: 3px;
        }
        &.FiniteStateGraph__Chunk--ZoneOpenPercentageState_slightlyOpen {
            height: 2px;
        }
        &.FiniteStateGraph__Chunk--ZoneOpenPercentageState_closed {
            height: 0px;
        }
    }
}
