.NotesFieldWrapper {
    width: 100%;
    margin-top: 10px;
    .MuiTextField-root {
        width: 100%;
    }
    .NotesControls {
        width: 100%;
        display: flex;
        transform: scaleY(1);
        transition: transform 100ms ease-in-out;
        justify-content: flex-end;
        button {
            margin: 5px;
        }
    }
    .NotesControls--hidden {
        transform: scaleY(0);
    }
}
