.ValveStateIcon {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16);
    --waterColor: var(--irrigation-main);
    --dryColor: #424242;
    &.ValveStateIcon--close {
        //background-color: var(--dryColor);
        //color: #424242;
        border: solid 4px #424242;
    }
    &.ValveStateIcon--open {
        background-color: var(--waterColor);
        //color: white;
        //stroke-width: 1px;
        //stroke: white;
        border: solid 4px var(--irrigation-dark);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    > p {
        font-size: 0.75rem;
        font-weight: 600;
    }
}
.VerificationStatusIcon {
    background-color: #6b6b6b;
    &.VerificationStatusIcon--error {
        background-color: var(--errorNormal);
    }
    &.VerificationStatusIcon--successful {
        background-color: var(--palette-main);
    }
}
.MuiSvgIcon-root.VerificationStatusIcon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    padding: 3px;
    color: rgba(255, 255, 255, 0.96);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16);
    background-color: #6b6b6b;
    &.VerificationStatusIcon--error {
        background-color: var(--errorNormal);
    }
    &.VerificationStatusIcon--successful {
        background-color: var(--palette-main);
    }
}
