.SensorSettings {
    .SettingsItemZoneSelect {
        .ZoneSelectAutocomplete {
            width: 25rem;
            .MuiChip-label {
                font-family: "Roboto Condensed";
                max-width: 10rem;
            }
        }
    }
    .SettingsItem.SettingsItemAcceptableRange,
    .SettingsItem.SettingsItemVerificationRange {
        border: solid rgba(0, 0, 0, 0.18) 1px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
        padding: 12px;
        display: flex;
        flex-direction: column;
        .SensorThresholdProtectionSwitch,
        .AcceptableVerificationRangeSwitch {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .MuiSvgIcon-root {
                font-size: 2rem;
                color: rgba(0, 0, 0, 0.31);
            }
        }
        .SensorThresholdProtectionSettings {
            display: flex;
            flex-direction: row;
            .LengthUnitTextField {
                margin: 0.25rem;
            }
        }
        .AcceptableValueRangeError {
            color: red;
        }
    }
    .SettingsItem.SettingsItemVerificationRange {
        .AcceptableVerificationRangeSettings {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .LengthUnitTextField {
                margin: 0.25rem;
            }
            .ValveStateIdentifier {
                display: flex;
                flex-direction: row;
                max-width: 15ch;
                justify-content: space-evenly;
                align-items: center;
                > div {
                    margin: 4px 5px;
                }
                > .StackedText {
                    line-height: 1;
                }
            }
        }
    }
}
.SensorSettingsZoneSelectEntry {
    display: flex;
    align-items: center;
    .ZoneSelectEntryBody {
        flex-grow: 2;
    }
    .ZoneSelectEntryTag {
        right: 0;
        width: 1.8rem;
        height: 1.8rem;
        background-color: var(--palette-light);
        border-radius: 50%;
        text-align: -webkit-center;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        color: #000000ca;
        min-width: 1.8rem;
        min-height: 1.8rem;
    }
    &.SensorSettingsZoneSelectEntry--default {
        .ZoneSelectEntryBody {
            font-style: italic;
        }
    }
}
