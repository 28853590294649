.GraphDemoPageContainer {
    padding-top: 2rem;
    padding-left: 4rem;
    max-width: 850px;
    max-height: calc(100% - var(--nav-bar-height));
    overflow: auto;
    padding-right: 40px;

    .GraphDemoTitle {
        margin-bottom: 1rem;
    }

    .fetchZonesButton {
        color: white;
    }

    .GraphDemoNewEntryContainer {
        display: flex;

        .GraphDemoZoneSelectContainer {
            margin-top: 1rem;
            display: flex;
            align-items: flex-start;

            .GraphDemoZoneSelectColumn {
                display: flex;
                flex-direction: column;
            }
            .GraphDemoZoneSelect {
                margin-right: 1rem;
                min-width: 18rem;
            }
        }

        .addNewEntryButton {
            width: 2.5rem;
            height: 2.5rem;
            top: 0.2rem;

            .addNewEntryButtonIcon {
                width: 2.5rem;
                height: 2.5rem;
            }
        }

        .deviceManualInputContainer {
            padding-top: 1rem;
            margin-left: 8rem;
            display: flex;

            .deviceManualInputColumn {
                display: flex;
                flex-direction: column;
                margin-right: 1rem;
                min-width: 20rem;
            }
        }
    }

    .displayedListsContainer {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-evenly;
    }
}

#root {
    // overflow-y: scroll;
}
