#main-logo {
    height: calc(var(--nav-bar-height) - 20px);
    border-radius: calc(var(--nav-bar-height) / 5);
    padding: 0.4em 0.8em;
}

a:hover {
    color: unset;
    text-decoration: underline;
}

.nav-drawer-select {
    min-width: 100px;
    height: 100%;
    max-height: var(--nav-bar-height);

    & .MuiInputBase-input {
        padding-top: 1.5rem;
        flex-grow: 1;
        padding-bottom: 0.2rem;
    }
}

.MainBrowserToolbar > * {
    max-height: var(--nav-bar-height);
}

.NavBarButton {
    max-height: var(--nav-bar-height);
}

.side-nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 900;
    height: 100vh;
    width: 33%;
    background: white;
}

@media (min-width: 768px) {
    .top-nav {
        justify-content: space-between;
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 50px;
    height: 50px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #7fcf32;
    border-color: #7fcf32 transparent #7fcf32 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
