.SproutIndividualVerification {
    //border-top: solid 5px   #6b6b6b;
    //&.SproutIndividualVerification--successful{
    //    border-top: solid 5px var(--palette-main)
    //}
    //&.SproutIndividualVerification--error{
    //    border-top: solid 5px  var(--errorNormal);
    //}
    .SproutIndividualVerification__Header {
        display: flex;
        flex-direction: row;
        padding-left: 5px;
        //align-items: center;
        .SproutIndividualVerification__HeaderTitle {
            padding-top: 3px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: right;
            text-align: right;
            //align-items: flex-end;
            margin-right: 8px;
            .SproutIndividualVerification__HeaderTitleText {
                display: flex;
                flex-direction: row;
                align-items: center;
                .IndividualValveState {
                    margin-left: 4px;
                    marginright: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .IndividualValveState__icon {
                        .ValveStateIcon {
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }
                }
                .SproutIndividualVerification__HeaderDescriptiveState {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    margin-left: 8px;
                    white-space: nowrap;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.38);
                }
            }
            .SproutIndividualVerification__HeaderTitleUpdatedAt {
                text-align: left;
                color: rgba(0, 0, 0, 0.38);
            }
        }

        .Spacer {
            flex-grow: 8;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .LiveText {
                background-color: var(--curStateFlagDark);
                color: white;
                border-radius: 4px;
                padding-left: 5px;
                margin-top: 0.5rem;
                padding-right: 5px;
                font-size: 0.8rem;
            }
        }
        .SproutIndividualVerification__HeaderResult {
            color: rgba(255, 255, 255, 0.89);
            flex-grow: 10;
            font-size: 2rem;
            max-width: 8ch;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 10px 0px 0px 10px;
            transform: translateX(2px);
            box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.39);
            > span {
                font-size: 2rem;
                padding: 4px;
            }
        }
    }
}
