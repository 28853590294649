.CardWarning {
    width: calc(100%);
    padding: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    overflow: visible;

    &::before {
        content: " ";
        height: 100%;
        width: calc(100% + 32px);
        left: -16px;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    // Pink is temporary for manual mode, we need an actual informational color. IDK. Maybe yellow or something?
    &.CardWarning_warningLevel--0 {
        color: rgba(255, 255, 255, 0.93);
        background-color: #3e2723;
        &::before {
            background-color: #3e2723;
        }
    }
    &.CardWarning_warningLevel--1 {
        color: rgba(255, 255, 255, 0.93);
        background-color: var(--warningNormal);
        &::before {
            background-color: var(--warningNormal);
        }
    }

    &.CardWarning_warningLevel--2 {
        color: rgba(255, 255, 255, 0.93);
        background-color: var(--errorNormal);
        &::before {
            background-color: var(--errorNormal);
        }
    }

    > h6 {
        font-size: 12px;
    }

    > p {
        margin-top: 3px;
        font-style: italic;
    }
}
