.ME.zone {
    stroke: var(--dark-shade, #82c926);
    stroke-width: 1pt;
    stroke-opacity: 1;
    fill-opacity: 0.2;
    fill: var(--status-color-normal, #82c926);
    transition:
        stroke 200ms ease-in-out,
        stroke-width 150ms ease-in-out,
        fill 200ms ease-in-out,
        fill-opacity 300ms ease-in-out,
        stroke-opacity 200ms ease-in-out;
}
.ME.zone:hover {
    stroke-opacity: 1;
    fill-opacity: 0.4;
    stroke-width: 3pt;
    fill: var(--status-color-dark, #82c926);
}
.ME.zone.ME--selected {
    stroke-width: 2pt;
    fill-opacity: 0.1;
    fill: var(--status-color-dimmed, #82c926);
    stroke: var(--status-color-dimmed, #82c926);
}
.ME.zone.ME--selected:hover {
    stroke-width: 3pt;
    fill-opacity: 0.2;
}
.ME.zone.ME--focused:hover {
    stroke-width: 3.5pt;
    fill-opacity: 0.4;
}
.ME.zone.ME--focused {
    stroke-width: 4pt;
    fill-opacity: 0.1;
    fill: var(--status-color-dimmed, #82c926);
    stroke: var(--status-color-dimmed, #82c926);
}
.ME.zone.ME--inactive:hover {
    stroke-width: 1pt;
    stroke-opacity: 0.8;
    fill-opacity: 0.35;
}
.ME.zone.ME--inactive {
    stroke: rgb(240, 255, 211);
    stroke-width: 0.5pt;
    stroke-opacity: 0.9;
    fill-opacity: 0.1;
    fill: rgb(240, 255, 211);
}
//
//.viewingNDVI .ME.zone{
//    fill: #FFFFFF55;
//    stroke: #FFFFFF95;
//}
.leaflet-pane.leaflet-shadow-pane {
    .ME.zone {
        stroke: var(--dark-shade, #82c926);
        stroke-width: 2pt;
        mask: url("#focusedZoneMask");
        stroke-opacity: 0;
        fill-opacity: 0;
        fill: var(--status-color-normal, #82c926);
        transition:
            stroke 200ms ease-in-out,
            stroke-width 150ms ease-in-out,
            fill 200ms ease-in-out,
            fill-opacity 300ms ease-in-out,
            stroke-opacity 200ms ease-in-out;
    }
    .ME.zone.ME--focused {
        stroke-width: 3pt;
        fill-opacity: 0.8;
        fill: var(--status-color-dimmed, #82c926);
        stroke: var(--status-color-dimmed, #82c926);
    }
    .ME.zone.ME--focused:hover {
        fill-opacity: 0.9;
    }
    .ME.zone.ME--selected {
        fill-opacity: 0.6;
        mask: url("#selectedZoneMask");
        fill: var(--status-color-dimmed, #82c926);
        stroke: var(--status-color-dimmed, #82c926);
    }
    .ME.zone.ME--selected:hover {
        fill-opacity: 0.6;
    }
}
