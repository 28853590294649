#main-logo-login {
    width: 40%;
    height: 40%;
    margin: 30px;
}

.login__center {
    padding: 1rem;
    width: 320px;
    max-width: 20rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 12px;
    text-align: center;

    position: absolute;
    left: 50%;
    top: calc(50% + var(--nav-bar-height, 0) / 2);
    transform: translate(-50%, -50%);
}

.login__center > div {
    margin: 6px;
}

button.LoginSubmitButton {
    margin: 20px;
}

.LoginTitle {
    margin-bottom: 20px;
}

.login__textField {
    width: 90%; 
    max-width: 400px;
    margin-bottom: 16px;
}

.login__helperText {
    white-space: normal;
    max-width: 100%;
    overflow-wrap: break-word;
    text-align: center;
}
