.TypographyWithMaxWidth {
    --maxWidth: 100px;
    --maxWidthUnitless: 100;
    --curWidth: 100;
}
.TypographyWithMaxWidthSuper.TypographyWithMaxWidth {
    max-width: fit-content;
    min-width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) calc(var(--maxWidth, 1)), transparent);
    transform-origin: center left;
    font-family: "Roboto Condensed";
}
.TypographyWithMaxWidth.TypographyWithMaxWidth--unchanged {
    font-family: "Roboto";
}
.TypographyWithMaxWidth.TypographyWithMaxWidth--condensedFont {
    font-family: "Roboto Condensed";
}

.TypographyWithMaxWidth.TypographyWithMaxWidth--smallText {
    transform-origin: bottom left;
    transform: scale(max(calc(var(--maxWidthUnitless, 1) / var(--curWidth, 1)), 0.6));
    --inlineVar: max(calc(var(--maxWidthUnitless, 1) / var(--curWidth, 1)), 0.6);
    mask-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1) calc(var(--maxWidth, 1px) / var(--inlineVar)),
        transparent
    );
}

.TypographyWithMaxWidth.TypographyWithMaxWidth--scale {
    --inlineVar: max(calc(var(--maxWidthUnitless, 1) / var(--curWidth, 1)), 0.6);
    transform: scale(max(calc(var(--maxWidthUnitless, 1) / var(--curWidth, 1)), 0.6));
    mask-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1) calc(var(--maxWidth, 1px) / var(--inlineVar)),
        transparent
    );
}

.TypographyWithMaxWidth.TypographyWithMaxWidth--scaleSmallText {
    --inlineVar: max(calc(var(--maxWidthUnitless, 1) / var(--curWidth, 1)), 0.3);
    transform: scaleX(max(calc(var(--maxWidthUnitless, 1) / var(--curWidth, 1)), 0.3)) scaleY(0.7);
    mask-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1) calc(var(--maxWidth, 1px) / var(--inlineVar)),
        transparent
    );
}
.TypographyWithMaxWidth.TypographyWithMaxWidth--scaleSmallTextWithElipsis {
    max-width: calc(var(--maxWidth) / 0.6);
    max-height: var(--lineHeight, fit-content);
    min-width: unset;
    /*white-space: normal;*/
    text-overflow: ellipsis;
    transform: scaleX(0.6) scaleY(0.6);
    mask-image: unset;
    -webkit-mask-image: unset;
    text-align: justify;
    word-spacing: -2px;
}

/*.TypographyWithMaxWidth.TypographyWithMaxWidth--scaleSmallTextWithElipsis::before{*/
/*    content: "...";*/
/*    position:absolute;*/
/*    left: calc(100% - 20px);*/
/*    z-index: 10000;*/
/*    width:15px;*/

/*}*/
