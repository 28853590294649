* {
    --palette-main: #8bc34a;
    --palette-dark: #5a9216;
    --palette-very-dark: #3c6011;
    --palette-light: rgb(190, 246, 122);
    --palette-text: #333333;

    --irrset-main: #ad4ac3;
    --irrset-light: #c679dc;
    --irrset-very-light: #e79dff;
    --irrset-dark: #761b8f;

    --TIP-main: #69efad;
    --TIP-light: #9fffcc;
    --TIP-very-light: #b5fcd9;
    --TIP-dark: #2bbc7d;
    --TIP-very-dark: #066c4b;

    --irrigation-main: #42a5f5;
    --irrigation-light: #80d6ff;
    --irrigation-very-light: #a1eeff;
    --irrigation-dark: #0077c2;

    --success-icon-main: #43a047;

    --error-icon-main: #ff0000;

    --stale-icon-light: rgba(255, 160, 0, 0.3);
    --stale-icon-medium: rgba(255, 160, 0, 0.6);
    --stale-icon-dark: rgba(255, 160, 0, 1);

    --unverified-icon-main: #afafaf;
    --unverified-text-main: #bebebe;

    --executed-irrigation: #0077c2;
    --scheduled-irrigation: #4ec4db;

    --warningComponents: 251, 140, 0;
    --warningNormal: #fb8c00;
    --warningDimmed: #c25e00;
    --warningDark: #e65100;

    --errorNormal: #e82222;
    --errorDimmed: #b00020;
    --errorDark: #9b011b;

    --beta: #2dd5be;
}

body {
    overflow: hidden;
    font-family: Roboto;
    font-weight: 400;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.slide-in-left-enter {
    transform: translateX(-100%);
}

.slide-in-left-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 200ms;
}

.slide-in-left-exit {
    transform: translateX(0%);
    opacity: 1;
}

.slide-in-left-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 200ms;
}
