.BlockValveZoneSelectorRoot {
    width: 100%;
    text-align: left;
    padding: 10px;
    padding-bottom: 20px;
    overflow: visible;
}

.BlockValveZoneSelectorBody {
    width: calc(100%);
    max-width: calc(100%);
    text-align: center;
    border: solid 1px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    box-shadow: #0000004d 1px 1px 4px;
}
.BlockValveSectionHeader {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
}
.BlockValveSectionHeader.SensorHeader {
    margin-top: 20px;
}
.cz_spacer {
    flex-grow: 100;
}
@keyframes BlockValveEntry {
    0% {
        max-height: 0;
        transform: scaleY(0);
        padding-top: 0;
        padding-bottom: 0;
    }
}

.BlockValveZoneSelectorBody .BlockValveZone {
    transition:
        transform 200ms ease-in-out,
        max-height 200ms ease-in-out,
        padding-top 200ms ease-in-out,
        padding-bottom 200ms ease-in-out;
}
.BlockValveZoneSelectorBody .BlockValveZone.BlockValveZone--active {
    animation: BlockValveEntry 200ms ease-in-out;
    animation-fill-mode: backwards;
    max-height: 3rem;
    transform: scaleY(1);
    padding-top: 16px;
    padding-bottom: 16px;
}
.BlockValveZoneSelectorBody .BlockValveZone.BlockValveZone--deleted {
    animation-fill-mode: forwards;
    /*animation: BlockValveExit 5000ms ease-in;*/
    max-height: 0;
    transform: scaleY(0);
    padding-top: 0;
    padding-bottom: 0px;
}
.BlockValveZoneSelectorBody .BlockValveIcon {
    transition: transform 200ms ease-in-out;
}
.BlockValveZoneSelectorBody .BlockValveIcon.BlockValveIcon--active {
    /*transform: scaleY(1);*/
}
.BlockValveZoneSelectorBody .BlockValveIcon.BlockValveIcon--deleted {
    transform: scaleY(0);
    /*padding-top: 0;*/
    /*padding-bottom: 0;*/
    /*padding-top: unset;*/
    /*padding-bottom: unset;*/
    /*transform: unset;*/
}
.BlockValveSensorSelection {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}
.BlockValveSensorSelection > * {
    flex-shrink: 1;
    flex-grow: 1;
    margin: 5px;
    width: 50%;
}
