.DynamicGraphXAxisRail {
    height: 20px;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.08);
    border-top: solid rgba(0, 0, 0, 0.9) 1px;
    z-index: 1;
}

.fullScreenBtn {
    display: flex;
    justify-content: flex-start;
}
